import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {retrieveTeacherList} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import BaseModal from "../../../components/modals/BaseModal";
import CreateOrUpdateInstitutionTeacher
    from "../../../components/institution/teachers/CreateOrUpdateInstitutionTeacher";
import {useAuth} from "../../../context/UserContext";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";
import {BranchReport} from "./BranchReport.js";

export default function InstitutionTeacherScreen() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();
    const [teacherList, setTeacherList] = useState([]);
    const [schoolBranchTeacherList, setSchoolBranchTeacherList] = useState([]);
    const {institutionId} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [showReport, setShowReport] = useState(false);
    const [selectedBranchId, setSelectedBranchId] = useState(null);

    useEffect(() => {
        retrieveAndSetTeacherList();
    }, []);

    const retrieveAndSetTeacherList = async () => {
        const {data, err} = await retrieveTeacherList(institutionId);
        if (err) {
            toast("Öğretmen listesi yüklenirken bir hata oluştu");
            return;
        }
        setTeacherList(data.teacherList);
        setSchoolBranchTeacherList(data.schoolBranchTeacherList);


    };

    const findBranches = (userId) => {

        const branchListForTeacher = schoolBranchTeacherList.filter(b => b.user.id === userId)
        if (!branchListForTeacher) return "-"
        const listOfBranch = []
        branchListForTeacher.map(b =>
            listOfBranch.push({name: b.schoolBranch.name, id: b.schoolBranch.id}))
        return listOfBranch
    };


    const handleAfterCreateOrUpdate = () => {
        setShowModal(false);
        retrieveAndSetTeacherList();
    };

    const RenderTableData = () => {
        return (
            <>
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title="Oluştur"
                            onClick={() => {
                                setSelectedTeacher(null)
                                setShowModal(true);
                            }}
                        />
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> isim</th>
                        <th scope="col" className="px-6 py-3"> Soyisim</th>
                        <th scope="col" className="px-6 py-3"> Kullanıcı Adı</th>
                        <th scope="col" className="px-6 py-3"> Okul</th>
                        <th scope="col" className="px-6 py-3"> Sınıflar</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {teacherList.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.lastname}</td>
                            <td className={`px-6 py-4`}>{data.username}</td>
                            <td className={`px-6 py-4`}>{data.school.name}</td>

                            <td className={`px-6 py-4`}>
                                {
                                    findBranches(data.id).map(b =>
                                        <PrimaryButton onClick={() => {
                                            setShowReport(false)
                                            setSelectedBranchId(null)
                                            setShowReport(true)
                                            setSelectedBranchId(b.id)
                                        }}
                                                       title={b.name}/>
                                    )


                                }

                            </td>
                            <td>
                                <PrimaryButton
                                    title={language.update}
                                    onClick={() => {
                                        setSelectedTeacher(data);
                                        setShowModal(true);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }

    const RenderCreateOrUpdateInstitutionTeacherModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateInstitutionTeacher
                    initialTeacher={selectedTeacher}
                    institutionId={institutionId}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };

    useEffect(() => {
        retrieveAndSetTeacherList();
    }, [showModal]);

    return (
        <>
            {RenderCreateOrUpdateInstitutionTeacherModal()}
            <div className="w-full">
                <MainHeader name="Öğretmenler"/>
                {
                    showReport && selectedBranchId ?
                        <BranchReport setShowReport={setShowReport} type="branch" selectedBranch={selectedBranchId}/> :
                        <div>
                            <RenderTableData/>
                        </div>
                }
            </div>
        </>
    );
}
