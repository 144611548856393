import { Component, createContext, useContext } from "react";

export const UserContext = createContext();

class UserContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInformation: null,
      institutionId: 0,
      institution: {name: ""},
      institutionList: [],
    };
    this.setUserInformation = this.setUserInformation.bind(this);
    this.setInstitutionList = this.setInstitutionList.bind(this);
    this.setInstitution = this.setInstitution.bind(this);
    this.setInstitutionId = this.setInstitutionId.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  setUserInformation(userInformation) {
    this.setState({ userInformation });
  }

  setInstitutionId(institutionId) {
    this.setState({ institutionId });
  }

  setInstitutionList(institutionList) {
    this.setState({ institutionList });
  }
  setInstitution(institution) {
    this.setState({ institution });
  }

  handleLogout() {
    this.setUserInformation(null);
    this.setInstitutionList([]);
    this.setInstitutionId(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          setUserInformation: this.setUserInformation,
          setInstitutionId: this.setInstitutionId,
          setInstitutionList: this.setInstitutionList,
          setInstitution: this.setInstitution,
          handleLogout: this.handleLogout,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const useAuth = () => {
  const context = useContext(UserContext);
  if (!context) throw Error("Context must be not null.");
  return context;
};

export default UserContextProvider;
