import MiniCountCard from "../../../../components/cards/MiniCountCard";
import React, {useEffect, useState} from "react";

export default function UsageCountPart (props){
    const {data} = props

    const [counts, setCounts] = useState({day:0, month:0,week:0,year:0})

    useEffect(() => {
       if(data){
           const scoreDayList = data.scoreDayList;
           const scoreMonthList = data.scoreMonthList;
           const scoreWeekList = data.scoreWeekList;
           const scoreYearList = data.scoreYearList;

           let day = 0;
           let month = 0;
           let week = 0;
           let year = 0;

           for(let i=0; i < scoreDayList.length;i++){
               day += Number(scoreDayList[i].gamePointCount)
           }

           for(let i=0; i < scoreMonthList.length;i++){
               month += Number(scoreMonthList[i].gamePointCount)
           }

           for(let i=0; i < scoreWeekList.length;i++){
               week += Number(scoreWeekList[i].gamePointCount)
           }

           for(let i=0; i < scoreYearList.length;i++){
               year += Number(scoreYearList[i].gamePointCount)
           }


           setCounts({day, month,week,year})

       }

    }, [data]);







    return (
        <div className="flex flex-row flex-wrap gap-y-4">
            <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Bugün"
                        count={counts.day + " kez"}
                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                        count2={data?.userDayList?.length + " öğrenci"}
                        backColor={"#e66363"}
                    />
                </div>
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Bu hafta"
                        count={counts.week + " kez"}
                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                        count2={data?.userWeekList?.length + " öğrenci"}
                        backColor={"#da7613"}
                    />
                </div>
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Bu ay"
                        count={counts.month + " kez"}
                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                        count2={data?.userMonthList?.length + " öğrenci"}
                        backColor={"#7fd1b9"}
                    />
                </div>
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Bu yıl"
                        count={counts.year + " kez"}
                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                        count2={data?.userYearList?.length + " öğrenci"}
                        backColor={"#7a6563"}
                    />
                </div>
            </div>
        </div>
    )
}