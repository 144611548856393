import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import MainHeader from "../../../components/headers/MainHeader";
import React, { useEffect, useState} from "react";
import {
    getSchoolUserStatisticsCount,
    retrieveAllGameData,
    retrieveEvolution,
    retrieveEvolutionCount,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {calculateAVGData} from "./calculate/CalculateData";
import EvolutionPart from "./reportParts/EvolutionPart";
import UsagePart from "./reportParts/UsagePart";
import SpeedPart from "./reportParts/SpeedPart";
import UsageCountPart from "./reportParts/UsageCountPart";
import DonutPart from "./reportParts/DonutPart";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const SchoolReport = (props) => {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();
    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);
    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);
    const {selectedInstitution, setShowReport} = props;
    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [, setUserStatistics] = useState(null);
    const [data, setData] = useState(null);

    const retrieveGameData = async () => {
        const {data, err} = await retrieveAllGameData("school", selectedInstitution.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);

        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);

        setData(data.scoreTotalList)
        setUsageCount(data)
    };

    const retrieveGameEvolution = async () => {
        const {data, err} = await retrieveEvolution("school", selectedInstitution.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        const {data, err} = await retrieveEvolutionCount("school", selectedInstitution.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    const retrieveUserStatisticsCount = async () => {
        const {data, err} = await getSchoolUserStatisticsCount(selectedInstitution.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setUserStatistics(data)
    };

    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
    }, []);





    return (
        <div className="w-full">
            <div className="my-2">
                <div className="flex flex-row justify-end">
                    <ApproveButton
                        title="GERİ"
                        onClick={() => {
                            setShowReport(false)
                        }}
                    />
                </div>
            </div>

            <div className="mt-12"/>

            <MainHeader name={language.reportSkillsRatio}/>
            {countData && avgData ? (
                <DonutPart countData={countData} avgData={avgData}  countDataAll={countDataAll} avgDataAll={avgDataAll}/>
            ) : null}
            <div className="mt-12"/>
            <MainHeader name={language.reportUsageRatio}/>
            <UsageCountPart data={usageCount}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportCognitive}/>
            <SpeedPart data={data}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportIntelligence}/>
            <EvolutionPart evolution={evolution}/>
            <div className="mt-12"/>
            <MainHeader name="Kullanım gelişimi"/>
            <UsagePart evolution={evolutionCount}/>

        </div>
    );
};
