import { ClimbingBoxLoader } from "react-spinners";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../context/AppContext";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const { setAppState } = useApp();

  return (
    <div className="flex flex-row h-full w-full px-4 py-12 bg-left-panel-selected">
      <div className="flex-1 flex flex-col items-center justify-center bg-left-panel rounded-3xl">
        <div className="w-[400px] my-10">
          <img className="w-full" src="/logos/Miamo4kids-Mindventure.png" />
        </div>
        <div className="px-4 text-white">
          <p>Lütfen tekrar giriş yapınız</p>
        </div>
        <div className="my-2">
          <PrimaryButton
            title={"Giriş yap"}
            onClick={() => {
                navigate("/");
              setAppState("LOGIN_REQUIRED");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
