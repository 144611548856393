import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import ApproveButton from "../../../components/buttons/ApproveButton";
import language from "../../../context/language.json";
import {
    getAllBranchList,
    getAllSchoolList,
    getAllUserList, passwordSaveData
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {useAuth} from "../../../context/UserContext";

export default function ExportUserPassword() {


    const [uploadFile, setUploadFile] = useState(null);
    const [excelStatus, setExcelStatus] = useState({data: null, state: null});
    const [studentData, setStudentData] = useState([]);


    const [allUsers, setAllUsers] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [allBranches, setAllBranches] = useState([]);

    const {
        institutionId,
        institution
    } = useAuth();


    const _getAllUserList = async () => {
        const {data, err} = await getAllUserList(studentData, institutionId);
        if (err) {
            toast("Kullanıcı yapılıken hata oluştu");
            return;
        }
        setAllUsers(data);
    };
    const _getAllBranchList = async () => {
        const {data, err} = await getAllBranchList(studentData, institutionId);
        if (err) {
            toast("Şube yapılıken hata oluştu");
            return;
        }
        setAllBranches(data);
    };
    const _getAllSchoolList = async () => {
        const {data, err} = await getAllSchoolList(studentData, institutionId);
        if (err) {
            toast("Okul yapılıken hata oluştu");
            return;
        }
        setAllSchools(data);
    };

    useEffect(() => {
        _getAllUserList()
        _getAllBranchList()
        _getAllSchoolList()

    }, []);


    useEffect(() => {
        const handleImportFromCSVOnChange = async () => {
            setExcelStatus({
                data: null,
                state: "WAITING",
            });
            const data = await uploadFile.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets["data"];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
            });
            setExcelStatus({
                data: jsonData,
                state: "READY",
            });
        };
        if (uploadFile) {
            handleImportFromCSVOnChange();
        }
    }, [uploadFile]);


    useEffect(() => {

        if (excelStatus.state === "READY") {
            const header = excelStatus.data[0];
            const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
            if (
                dataArray.length > 0
            ) {
                const readStudents = []
                for (const data of dataArray) {

                    readStudents.push({
                        school: data[0],
                        name: data[1],
                        lastname: data[2],
                        branch: data[3],
                        username: data[4],
                        password: data[6],
                        type: data[7],
                        userId: null,
                        branchId: null,
                        schoolId: null

                    })

                }
                setStudentData(readStudents)

            }
        }
    }, [excelStatus]);



    const studentCheckData = async () => {

        const newData = []
        studentData.map(s => {
            let findUser = allUsers.find(u => (u.username + "").toLowerCase().trim() === (s.username + "").toLowerCase().trim() || (u.email + "").toLowerCase().trim() === (s.username + "").toLowerCase().trim());
            if (findUser) {
                s.userId = findUser.id
            }
            const findSchool = allSchools.find(c => c.name.toLowerCase().trim() === s.school.toLowerCase().trim());
            if (findSchool) {
                s.schoolId = findSchool.id
            }
            const findBranch = allBranches.find(c => c.name.toLowerCase().trim() === s.branch.toLowerCase().trim() && c.school.id === s.schoolId);
            if (findBranch) {
                s.branchId = findBranch.id
            }

            newData.push(s)
        })
        setStudentData(newData)
    };


    const studentSaveData = async () => {
        const {data, err} = await passwordSaveData(studentData);
        if (err) {
            toast("Kayıt yapılıken hata oluştu");
            return;
        }
        setStudentData(data);
    };


    const RenderTableData = () => {
        return (
            <>
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title={language.control}
                            onClick={() => {
                                studentCheckData()
                            }}
                        />
                        <ApproveButton
                            title={language.save}
                            onClick={() => {
                                studentSaveData()
                            }}
                        />
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> User ID</th>
                        <th scope="col" className="px-6 py-3"> School ID</th>
                        <th scope="col" className="px-6 py-3"> Branch ID</th>


                        <th scope="col" className="px-6 py-3"> schoolName</th>
                        <th scope="col" className="px-6 py-3"> name</th>
                        <th scope="col" className="px-6 py-3"> lastname</th>
                        <th scope="col" className="px-6 py-3"> branchName</th>
                        <th scope="col" className="px-6 py-3"> username</th>
                        <th scope="col" className="px-6 py-3"> password</th>

                        <th scope="col" className="px-6 py-3"> type</th>


                    </tr>
                    </thead>
                    <tbody>
                    {studentData.map((data, key) => (
                        <tr key={key} className="bg-white border-b">

                            <td className={`px-6 py-4`}>{data.userId}</td>
                            <td className={`px-6 py-4`}>{data.schoolId}</td>
                            <td className={`px-6 py-4`}>{data.branchId}</td>


                            <td className={`px-6 py-4`}>{data.school}</td>
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.lastname}</td>
                            <td className={`px-6 py-4`}>{data.branch}</td>
                            <td className={`px-6 py-4`}>{data.username}</td>
                            <td className={`px-6 py-4`}>{data.password}</td>

                            <td className={`px-6 py-4`}>{data.type}</td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }


    const handleOnChange = (type, value) => {
        if (type === "uploadFile") {
            setUploadFile(value[0]);
        }
    };


    return (
        <div>

            <input type="file"
                   onChange={(event) => {
                       handleOnChange("uploadFile", {
                           ...event.target.files,
                       });
                   }}
            />

            <RenderTableData/>

        </div>
    )
}