import {useApp} from "../../../context/AppContext";
import {getTeacherBranchList} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../context/UserContext";
import InputGroup from "../../../components/inputs/InputGroup";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {BranchReport} from "../../institution/page/BranchReport.js";
import InstitutionStudentScreen from "../../institution/page/InstitutionStudentScreen";

export default function DashboardTeacherScreen() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();

    const {
        handleLogout,
        userInformation,
        institutionId,
        setInstitutionId,
        setInstitution,
        institutionList,
    } = useAuth();



    const [branchList, setBranchList] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [branchSize, setBranchSize] = useState(0);
    const [showReport, setShowReport] = useState(true);



    const retrieveAndSetBranchList = async () => {
        const {data, err} =
            await getTeacherBranchList(
                userInformation.id
            );
        if (err) {
            toast("Şube listesi gelirken bir hata oluştu");
            return;
        }
        setBranchList(data);
        if (data.length > 0) {
            setSelectedBranchId(data[0].id)
            setBranchSize(data.length)
        }
    };

    useEffect(() => {
        retrieveAndSetBranchList()
    }, []);

    const retrieveBranchList = () => {
        const branchListWithSchool = []
        branchList.map(b =>
            branchListWithSchool.push({...b, name: `${b.name} ${b.school.name}`})
        )
        return branchListWithSchool;
    }






    return (
        <>

            <div className="w-full">
                <div className="w-full flex flex-row flex-wrap">
                    {
                        branchSize > 1 ? <div className="w-full lg:w-1/4 xl:w-1/4">
                            <InputGroup
                                type={"select"}
                                optionList={retrieveBranchList()}
                                value={selectedBranchId}
                                onChange={(e) => {
                                    setSelectedBranchId(e.target.value)
                                }}
                            />
                        </div> : null
                    }





                    <div className="w-full lg:w-1/4 xl:w-1/4">
                        <ApproveButton
                            title="Sınıf Raporu"
                            onClick={() => {
                                setShowReport(true);
                            }}
                        />

                        <ApproveButton
                            title="Öğrenciler"
                            onClick={() => {
                                setShowReport(false);
                            }}
                        />
                    </div>


                    {
                        showReport === false && selectedBranchId ?
                            <InstitutionStudentScreen teacherBranchId={selectedBranchId} getBranch={{id: selectedBranchId}} />
                            : null
                    }


                </div>
                <div>
                    {
                        showReport === true && selectedBranchId ?
                            <BranchReport hiddenButton={true} setShowReport={setShowReport} type="branch"
                                          selectedBranch={selectedBranchId}/>
                            : null
                    }


                </div>
            </div>
        </>
    )
}