
import React, {useContext} from "react";
import {UserContext} from "../../../context/UserContext";
import {StudentReport} from "../../institution/page/StudentReport";


export const DashboardScreen = (props) => {

    const userContext = useContext(UserContext);
    const {userInformation} = userContext;


    return (
        <div className="w-full">
            <StudentReport selectedStudent={userInformation}/> :

        </div>
    );
};
