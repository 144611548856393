const MiniCountCard = (props) => {
    const {title, count, textList, icon, backgroundColor, borderColor, count2, backColor} = props;

    return (
        <div
            className={`${backgroundColor} ${borderColor} min-h-[120px] min-w-[200px] border rounded-lg p-2 text-white`}
            style={{
                boxShadow:
                    "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
                backgroundColor:`${backColor ? backColor : ""}`

            }}
        >
            <div className="h-full flex flex-col justify-between">
                <div className="border-b-2">
                    <p className="text-xl">{title}</p>
                </div>
                {count ? (
                    <div>
                        <div className="flex flex-row justify-between">
                            <p className="text-3xl">{count}</p>
                        </div>
                        <div>{icon}</div>
                        <div className="flex flex-row justify-between">
                            <p className="text-3xl">{count2}</p>
                        </div>
                        <div>{icon}</div>
                    </div>


                ) : null}
                {textList ? (
                    <div className="flex flex-row justify-between flex-wrap">
                        {textList.map((text, key) => (
                            <div key={key} className="m-auto">
                                <p className="text-base">{text}</p>
                            </div>
                        ))}
                        <div>{icon}</div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default MiniCountCard;
