import React from "react";
import { useEffect, useState } from "react";
import MainHeader from "../../../components/headers/MainHeader";
import BaseTable from "../../../components/tables/BaseTable";
import { toast } from "react-toastify";
import {useApp} from "../../../context/AppContext";

export default function InstitutionReportScreen() {
  const {
    setAppState,
    setTheme,
    setPageList,
    _setSelectedStudent,
    _setSelectedTeacher,
    _setSelectedBranch,
    _setSelectedSchool,
    setRole,
    appState,
    theme,
    pageList,
    _selectedSchool,
    _selectedBranch,
    _electedTeacher,
    _selectedStudent,
    role
  } = useApp();
  const [reportList, setReportList] = useState([]);
  useEffect(() => {
    setReportList([
      {
        name: "Öğrenci raporu",
      },
      {
        name: "Sınıf raporu",
      },
      {
        name: "Okul raporu",
      },
      {
        name: "Marka raporu",
      },
      {
        name: "Karşılaştırılmalı sınıf raporu",
      },
      {
        name: "Gelişim raporu",
      },
    ]);
  }, []);

  const RenderTableData = () => {
    return (
      <div className="relative overflow-x-auto">
        <BaseTable
          headerList={[{ text: "RAPOR SEÇİMİ" }, { text: "" }]}
          dataList={reportList}
          dataKeyList={[{ key: "name" }]}
          constructible={true}
          handleOnConstructOnClick={() => {
            toast("Rapor oluşturulamıyor. ");
          }}
        />
      </div>
    );
  };

  return (
    <div className="w-full">
      <MainHeader name="Raporlar" />
      <div>
        <RenderTableData />
      </div>
    </div>
  );
}
