import React, {useContext, useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {UserContext} from "../../../context/UserContext";
import {
    retrieveBranchListByInstitutionIdSeasonIdGradeId,
    retrieveBranchStudentList,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import BaseModal from "../../../components/modals/BaseModal";
import CreateOrUpdateInstitutionStudent from "../../../components/institution/student/CreateOrUpdateInstitutionStudent";
import InputGroup from "../../../components/inputs/InputGroup";
import SelectGrade from "../../../components/SelectGrade";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {StudentReport} from "./StudentReport";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";
import ExportExcel from "./ExportExcel";

export default function InstitutionStudentScreen(props) {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();

    const userContext = useContext(UserContext);
    const {getBranch, teacherBranchId} = props;
    const {institutionId} = userContext;
    const [studentList, setStudentList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [branchList, setBranchList] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [showReport, setShowReport] = useState(false);

    const [showExcel, setShowExcel] = useState(false);


    const [summary, setSummary] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [treeList, setTreeList] = useState([]);


    useEffect(() => {
        const retrieveAndSetBranchList = async () => {
            const {data, err} =
                await retrieveBranchListByInstitutionIdSeasonIdGradeId(
                    institutionId,
                    selectedGradeId
                );
            if (err) {
                toast("Şube listesi gelirken bir hata oluştu");
                return;
            }
            setBranchList(data);
            if (data.length > 0) {
                setSelectedBranchId(data[0]["id"]);
            }
        };

        if (!selectedGradeId) return;
        retrieveAndSetBranchList();
    }, [selectedGradeId]);


    useEffect(() => {
        if (!selectedBranchId) return;
        retrieveAndSetStudentList(selectedBranchId);
    }, [selectedBranchId]);

    useEffect(() => {
        if (getBranch) retrieveAndSetStudentList(getBranch.id);

    }, [selectedBranchId]);


    useEffect(() => {
        if (!teacherBranchId) return;
        setSelectedBranchId(teacherBranchId)

    }, []);


    const retrieveAndSetStudentList = async (id) => {
        if (id) {
            setSelectedBranchId(id)
            const {data, err} = await retrieveBranchStudentList(id);
            if (err) {
                toast("Öğrenci listesi gelirken bir hata oluştu", err);
                return;
            }
            if (data.users) {
                setStudentList(data.users);
            }
            if (data.summary) {
                setSummary(data.summary);
            }
            if (data.gameList) {
                setGameList(data.gameList);
            }
            if (data.treeList) {
                setTreeList(data.treeList);
            }
        }

    };


    const handleAfterCreateOrUpdate = () => {
        setShowModal(false);
        retrieveAndSetStudentList(selectedBranchId);
    };

    const findTotalPoint = (userId) => {
        const user = findUser(userId)
        if (user) {
            return user.gamePoints
        }
        return "-"
    };
    const findLastLogin = (userId) => {
        const user = findUser(userId)
        if (user) {
            return user.gameAt[2] + "." + user.gameAt[1] + "." + user.gameAt[0]
        }
        return "-"
    };
    const findGame = (userId) => {
        const user = findUser(userId)
        if (user) {
            const gameId = user.gameId
            const game = gameList.find(g => g.id === gameId)
            return game.name
        }
        return "-"
    };
    const findTree = (userId) => {
        const user = findUser(userId)
        if (user) {
            const treeId = user.treeId
            const tree = treeList.find(g => g.id === treeId)
            return tree.name
        }
        return "-"
    };

    const findUser = (userId) => {
        return summary.find(u => u.userId === userId)
    };




    const RenderTableData = () => {
        return (

            <>
                {
                    getBranch ? null :
                        <div className="my-2">
                            <div className="flex flex-row justify-end">

                                {
                                    selectedBranchId ?
                                        <ApproveButton
                                            title="Oluştur"
                                            onClick={() => {
                                                setSelectedStudent(null)
                                                setShowModal(true);
                                            }}
                                        />
                                        :null
                                }

                                <ApproveButton
                                    title="Excel Aktar"
                                    onClick={() => {
                                        setShowExcel(true);
                                    }}
                                />
                            </div>
                        </div>
                }

                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> isim</th>
                        <th scope="col" className="px-6 py-3"> Soyisim</th>
                        <th scope="col" className="px-6 py-3"> Yıldız</th>

                        <th scope="col" className="px-6 py-3"> Toplam Puan</th>
                        <th scope="col" className="px-6 py-3"> Son giriş</th>
                        <th scope="col" className="px-6 py-3"> Oyun</th>
                        <th scope="col" className="px-6 py-3"> Ağaç</th>
                        {
                            getBranch ? null : <th></th>
                        }

                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {studentList.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.lastname}</td>
                            <td className={`px-6 py-4`}>{data.starCount}</td>


                            <td className={`px-6 py-4`}>{findTotalPoint(data.id)}</td>
                            <td className={`px-6 py-4`}>{findLastLogin(data.id)}</td>
                            <td className={`px-6 py-4`}>{findGame(data.id)}</td>
                            <td className={`px-6 py-4`}>{findTree(data.id)}</td>

                            {
                                getBranch ? null : <td>
                                    <PrimaryButton
                                        title={language.update}
                                        onClick={() => {
                                            setShowModal(true);
                                            setSelectedStudent(data);
                                        }}
                                    />
                                </td>
                            }
                            <td>
                                {
                                    findLastLogin(data.id) === "-" ? null :  <PrimaryButton
                                        title={language.report}
                                        onClick={() => {
                                            setShowReport(true);
                                            setSelectedStudent(data);
                                        }}
                                    />
                                }

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }

    const RenderCreateOrUpdateInstitutionStudentModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateInstitutionStudent
                    initialStudent={selectedStudent}
                    institutionId={institutionId}
                    gradeId={selectedGradeId}
                    branchId={selectedBranchId}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };

    const retrieveBranchList = () => {
        const branchListWithSchool = []
        branchList.map(b =>
            branchListWithSchool.push({...b, name: `${b.name} ${b.school.name}`})
        )
        return branchListWithSchool;
    }

    return (
        <>
            {!getBranch ? RenderCreateOrUpdateInstitutionStudentModal() : null}
            <div className="w-full">
                <MainHeader name="Öğrenciler" setShowReport={setShowReport}/>
                {
                    getBranch ? null :
                        <div className="w-full flex flex-row flex-wrap">

                            <div className="w-full lg:w-1/2 xl:w-1/2">
                                <div className="lg:ml-2">
                                    <SelectGrade value={selectedGradeId} onChange={setSelectedGradeId}/>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2 xl:w-1/2">
                                <div className="lg:ml-2">
                                    <InputGroup
                                        type={"select"}
                                        optionList={retrieveBranchList()}
                                        value={selectedBranchId}
                                        onChange={(e) => {
                                            retrieveAndSetStudentList(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                }
                <div>
                    {
                        showExcel ? <ExportExcel setShowExcel={setShowExcel}/> :

                            showReport ? <StudentReport branchId={selectedBranchId} setShowReport={setShowReport}
                                                        selectedStudent={selectedStudent}/> :
                                <RenderTableData/>
                    }
                </div>
            </div>
        </>
    );
}
