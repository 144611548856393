import React, {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {
    createCommands,
    deleteCommands,
    retrieveCommands, retrieveSkills, updateCommands
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import InputGroup from "../../../components/inputs/InputGroup";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

export default function CommentTask() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();

    const [commandList, setCommandList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState(null);


    const [inputs, setInputs] = useState({
        mainGenre: {id: 0},
        id: 0,
        min: 0,
        max: 0,
        commandText: ""
    });

    useEffect(() => {
        if (selectedCommand) {
            setInputs({
                mainGenre: {id: selectedCommand.mainGenre.id},
                id: selectedCommand.id,
                min: selectedCommand.min,
                max: selectedCommand.max,
                commandText: selectedCommand.commandText
            });
            setSelectedSkill(selectedCommand.mainGenre)
        }
    }, [selectedCommand]);


    const retrieveSkillData = async () => {
        const {data, err} = await retrieveSkills();
        setSkillList(data)
        if (err) {
            toast("Beceri listesi gelirken bir hata oluştu");
            return;
        }
    };

    const retrieveCommandData = async () => {
        const {data, err} = await retrieveCommands();
        setCommandList(data)
        if (err) {
            toast("Yorum listesi gelirken bir hata oluştu");
            return;
        }

    };

    useEffect(() => {
        retrieveSkillData();
        retrieveCommandData();
    }, []);


    const handleUpdateOnClick = async (id) => {
        const {data, err} = await updateCommands({
            mainGenre: {id: selectedSkill.id},
            id: id,
            min: Number(inputs.min),
            max: Number(inputs.max),
            commandText: inputs.commandText
        });
        if (err) {
            toast("Yprum güncellenirken bir hata oluştu");
            return;
        }
        retrieveCommandData();
    };

    const handleCreateOnClick = async () => {
        const {data, err} = await createCommands({
            mainGenre: {id: Number(selectedSkill.id)},
            min: Number(inputs.min),
            max: Number(inputs.max),
            commandText: inputs.commandText
        });
        if (err) {
            toast("Yorum oluşturulurken bir hata oluştu");
            return;
        }
        retrieveCommandData();
    };

    const handleDeleteOnClick = async (id) => {
        const {data, err} = await deleteCommands(id);
        if (err) {
            toast("Yorum silinirken bir hata oluştu");
            return;
        }
        retrieveCommandData();
    };

    const handleOnChange = (key, value) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            [key]: value,
        }));
    };

    const addCommand = () => {
        return (<>
                <div style={{width:"100%"}}>
                    <div className="mx-2">
                        <InputGroup
                            type="text"
                            name="Yorum"
                            value={inputs.commandText}
                            onChange={(e) => handleOnChange("commandText", e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-row flex-wrap">


                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Min"
                                value={inputs.min}
                                onChange={(e) => handleOnChange("min", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Max"
                                value={inputs.max}
                                onChange={(e) => handleOnChange("max", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    <ApproveButton
                        title="Yeni"
                        onClick={() => {
                            handleCreateOnClick()
                        }}
                    />
                    <ApproveButton
                        title="Güncelle"
                        onClick={() => {
                            handleUpdateOnClick()
                        }}
                    />
                </div>


            </>
        )
    }


    const RenderTableData = () => {
        return (
            <>

                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Beceri</th>
                        <th scope="col" className="px-6 py-3"> Min</th>
                        <th scope="col" className="px-6 py-3"> Max</th>
                        <th scope="col" className="px-6 py-3"> Yorum</th>

                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {commandList ? commandList.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.mainGenre.name}</td>
                            <td className={`px-6 py-4`}>{data.min}</td>
                            <td className={`px-6 py-4`}>{data.max}</td>
                            <td className={`px-6 py-4`}>{data.commandText}</td>


                            <td>
                                <PrimaryButton
                                    title={language.update}
                                    onClick={() => {
                                        setSelectedCommand(data)
                                        //handleUpdateOnClick(data.id);
                                    }}
                                />
                            </td>
                            <td>
                                <PrimaryButton
                                    title={"Sil"}
                                    onClick={() => {
                                        handleDeleteOnClick(data.id);
                                    }}
                                />
                            </td>
                        </tr>
                    )) : null}
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <>

            <div className="w-full">
                <MainHeader name="Yorumlar"/>


                <div className="w-full flex flex-row flex-wrap">


                    <div className="w-full lg:w-1/2 xl:w-1/2">
                        <div className="lg:ml-2">
                            <InputGroup
                                type={"select"}
                                optionList={skillList}
                                value={selectedSkill?.id}
                                onChange={(e) => {
                                    setSelectedSkill({id: e.target.value});
                                }}
                            />
                        </div>
                    </div>
                    {
                        addCommand()
                    }
                </div>
                <div>
                    <RenderTableData/>
                </div>
            </div>
        </>
    );
}
