const InputGroup = (props) => {
  const { type, name, value, placeholder, onChange, optionList } = props;

  const constructOptionList = () => {
    return <>
        <option>SEÇİNİZ</option>
        {optionList.map((option, key) => (
            <option key={key} value={option.id}>{option.name}</option>
        ))}
    </>
  };

    if (type === "text") {
        return (
            <div className="w-full my-2">
        <div>
          <p>{name}</p>
        </div>
        <input
          className="w-full px-4 py-2 rounded-lg border text-black"
          type={type}
          onChange={onChange}
          value={value == null ? '' : value}
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === "password") {
    return (
      <div className="w-full my-2">
        <div>
          <p>{name}</p>
        </div>
        <input
          className="w-full px-4 py-2 rounded-lg border text-black"
          type={type}
          onChange={onChange}
          value={value == null ? '' : value}
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type === "select") {
    return (
      <div className="w-full my-2">
        <div>
          <p>{name}</p>
        </div>
        <select
          className="w-full px-4 py-2 rounded-lg border text-black"
          type={type}
          onChange={onChange}
          value={value == null ? '' : value}
          placeholder={placeholder}
        >
          {constructOptionList()}
        </select>
      </div>
    );
  } else if (type === "date" || type === "datetime-local") {
    return (
      <div className="w-full my-2">
        <div>
          <p>{name}</p>
        </div>
        <input
          className="w-full px-4 py-2 rounded-lg border text-black"
          type={type}
          onChange={onChange}
          value={value == null ? '' : value}
          placeholder={placeholder}
        />
      </div>
    );
  }
};

export default InputGroup;
