import MiniCountCard from "../../../../components/cards/MiniCountCard";
import React, {useEffect, useState} from "react";

export default function StudentHomeworkPart (props){
    const {studentHomework} = props
    const [count, setCount] = useState(0);
    const [homework, setHomework] = useState(null);

    const findHomeworks=()=>{
        return studentHomework && studentHomework.homeworkList ? studentHomework.homeworkList.map((homework,key)=>
        <span> {homework.treeId.name} </span>
        ): null
    }

    useEffect(() => {

    }, []);

    return (
        <div className="">
            <div className="w-100 ">
                <div className="mx-4">
                    <MiniCountCard
                        title="Görevlerim"
                        textList={[findHomeworks()]}
                        icon={null}
                        backgroundColor={"bg-red-400"}
                        borderColor={"border-red-400"}
                    />
                </div>
            </div>


        </div>
    )
}
