import { useEffect, useState } from "react";
import {createBranch, updateBranch} from "../../../requests/ApiOperitions";
import { toast } from "react-toastify";
import InputGroup from "../../inputs/InputGroup";
import PrimaryButton from "../../buttons/PrimaryButton";
import ApproveButton from "../../buttons/ApproveButton";
import language from "../../../context/language.json";

export default function CreateOrUpdateInstitutionBranch(props) {
  const { initialBranch, schoolId, grade, handleAfterCreateOrUpdate } =
    props;
  const [inputs, setInputs] = useState({
    school: {id: schoolId},
    grade: grade,
    name: "",
    id:null
  });

  useEffect(() => {
    if (initialBranch) {
      setInputs({
        school: initialBranch.school,
        grade: initialBranch.grade,
        name: initialBranch.name,
        schoolPeriod: initialBranch.schoolPeriod,
        id: initialBranch.id
      });
    }
  }, [initialBranch]);


  const handleOnChange = (key, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [key]: value,
    }));
  };

  const handleUpdateOnClick = async () => {

    const { data, err } = await updateBranch({
      ...inputs
    });
    if (err) {
      toast("Şube oluşturulurken bir hata oluştu");
      return;
    }


    handleAfterCreateOrUpdate();
  };

  const handleCreateOnClick = async () => {
    const { data, err } = await createBranch({
      ...inputs
    });
    if (err) {
      toast("Şube oluşturulurken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  return (
    <div className="w-full">
      <div className="my-2">
        <div className="font-bold">
          {inputs.id ? "Şube güncelle" : "Şube oluştur"}
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-400"></div>
      <div className="my-1">
        <div className="flex flex-row flex-wrap">
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                type="text"
                name="Şube Adı"
                value={inputs.name}
                onChange={(e) => handleOnChange("name", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-1">
        <div className="flex flex-row justify-end">
          {inputs.id ? (
            <PrimaryButton title={language.update} onClick={handleUpdateOnClick} />
          ) : (
            <ApproveButton title={language.insert} onClick={handleCreateOnClick} />
          )}
        </div>
      </div>
    </div>
  );
}
