import { useEffect } from "react";
import { useAuth } from "./context/UserContext";
import { useApp } from "./context/AppContext";
import {
  authenticateByParams,
  retrieveSchoolList,
} from "./requests/ApiOperitions";
import { toast } from "react-toastify";

const App = (props) => {
  const {
    setUserInformation,
    setInstitutionId,
    setInstitutionList,
  } = useAuth();
  const { appState, setAppState } = useApp();


  useEffect(() => {

    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    if (!accessToken || !refreshToken) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      if (window.location.pathname === "/") {
        setAppState("LOGIN_REQUIRED");
      } else {
        setAppState("ERROR");
      }
    } else {
      authenticateAndSetByParams(accessToken, refreshToken);
    }
  }, []);

  useEffect(() => {
    const retrieveAndSetInstitutionList = async () => {
      const { data, err } = await retrieveSchoolList();
      if (err) {
        toast("Kurum listesi sorgulanırken bir hata oluştu");
        setAppState("ERROR");
        return;
      }
      if (data.length === 0) {
        toast("Boş kurum listesi bulundu");
        setAppState("ERROR");
        return;
      }
      if (data.length > 0) {
        setInstitutionId(data[0]["id"]);
      }
      setInstitutionList(data);
      setAppState("DASHBOARD");
    };
    if (appState === "ERROR") {
    } else if (appState === "INSTITUTION_QUERY_REQUIRED") {
      retrieveAndSetInstitutionList();
    }
  }, [appState]);

  const authenticateAndSetByParams = async (
    oldAccessToken,
    oldRefreshToken
  ) => {
    const { data, err } = await authenticateByParams({
      accessToken: oldAccessToken,
      refreshToken: oldRefreshToken,
    });

    console.log(err)
    if (err) {
      setAppState("LOGIN_REQUIRED");
      return;
    }
    const { accessToken, refreshToken, user } = data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    setUserInformation(user);
    if (user.role !== "SUPER_ADMIN") {
      setInstitutionId(user.school.id);
      setAppState("DASHBOARD");
    }
    else {

    }
    setAppState("INSTITUTION_QUERY_REQUIRED");
  };

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full">{props.children}</div>
    </div>
  );
};

export default App;
