import React, {useContext} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {UserContext} from "../../../context/UserContext";

import {useApp} from "../../../context/AppContext";
import ApproveButton from "../../../components/buttons/ApproveButton";

export default function HomeworkCheck(props) {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();

    const userContext = useContext(UserContext);
    const {selectedIndex, homeworkList, setShowReport} = props;

    const findSState = (id) => {

        const result = homeworkList.homeworkList[selectedIndex].userGameData.filter(c=>c.user.id === id)
        if(result.length === 0) {
            return "YAPMADI"
        }
        else{
            return `${result.length} kez oynandı. Son tarih:${convertDate(result[result.length-1].gameAt)} Son puan:${result[result.length-1].gamePoints}`
        }
    };

const convertDate=(parts)=>{

    return `${parts[2]}.${parts[1]}.${parts[0]}`
}





    const RenderTableData = () => {
        return (
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Öğrenci</th>
                        <th scope="col" className="px-6 py-3"> Yapma Tarihi</th>
                        <th scope="col" className="px-6 py-3"> Durum</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        homeworkList.studentList ?
                            homeworkList.studentList.map((data, key) => (
                                <tr key={key} className="bg-white border-b">
                                    <td className={`px-6 py-4`}>{data.name +" "+ data.lastname}</td>
                                    <td className={`px-6 py-4`}>{data.name}</td>
                                    <td className={`px-6 py-4`}>{findSState(data.id)}</td>

                                </tr>
                            )) : null
                    }
                    </tbody>
                </table>
        )
    }





    return (
        <div className="w-full">

            <div className="my-2">
                <div className="flex flex-row justify-end">
                    <ApproveButton
                        title="GERİ"
                        onClick={() => {
                            setShowReport(false)
                        }}
                    />
                </div>
            </div>

            <div className="w-full flex flex-row flex-wrap">
                <div className="w-full lg:w-1/2 xl:w-1/3">
                    <div className="lg:mr-2">

                    </div>
                </div>
                <div className="w-full lg:w-1/2 xl:w-1/3">
                    <div className="lg:ml-2">

                    </div>
                </div>
                <div className="w-full lg:w-1/2 xl:w-1/3">
                    <div className="lg:ml-2">

                    </div>
                </div>
            </div>
            <div>
                <RenderTableData/>
            </div>
        </div>
    );
}
