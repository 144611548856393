import MainHeader from "../../../../components/headers/MainHeader";
import MiniCountCard from "../../../../components/cards/MiniCountCard";
import {useEffect, useState} from "react";
import {retrieveSchoolSummary} from "../../../../requests/ApiOperitions";
import {toast} from "react-toastify";

export default function SchoolCountPart() {

    const [summery, setSummery] = useState(null);
    const getSchoolSummary = async () => {
        const {data, err} = await retrieveSchoolSummary();
        if (err) {
            toast("İstatistik bilgileri sorgulanırken bir hata oluştu");
            return;
        }
        setSummery(data)

    };
    useEffect(() => {
        getSchoolSummary();
    }, []);


    return (
        <div className="flex flex-row flex-wrap gap-y-4">
            <div className="w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Toplam Aktif Kurum Sayısı"
                        count={summery?.schoolCount}
                        icon={null}
                        backgroundColor={"bg-orange-400"}
                        borderColor={"border-orange-400"}
                        backColor={"#e66363"}
                    />
                </div>
            </div>
            <div className="w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Toplam Öğrenci Sayısı"
                        count={summery?.studentCount}
                        icon={null}
                        backgroundColor={"bg-red-400"}
                        borderColor={"border-red-400"}
                        backColor={"#da7613"}
                    />
                </div>
            </div>
            <div className="w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Toplam Oynanma Sayısı"
                        count={summery?.playsCount}
                        icon={null}
                        backgroundColor={"bg-purple-400"}
                        borderColor={"border-purple-400"}
                        backColor={"#7fd1b9"}
                    />
                </div>
            </div>
            <div className="w-1/4 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="Toplam Kullanıcı Sayısı"
                        count={summery?.userCount}
                        icon={null}
                        backgroundColor={"bg-green-400"}
                        borderColor={"border-green-400"}
                        backColor={"#7a6563"}
                    />
                </div>
            </div>
        </div>
    )
}