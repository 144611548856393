import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import BaseTable from "../../../components/tables/BaseTable";

export default function InstitutionLogScreen() {
    const [logList, setLogList] = useState([]);

    useEffect(() => {
        setLogList([
            {
                name: " ",
                level: 5,
            },
            {
                name: " ",
                level: 10,
            },
        ]);
    }, []);

    const RenderTableData = () => {
        return (
            <div className="relative overflow-x-auto">
                <BaseTable
                    headerList={[{text: "isim"}, {text: "seviye"}]}
                    dataList={logList}
                    dataKeyList={[{key: "name"}, {key: "level"}]}
                />
            </div>
        );
    };

    return (
        <div className="w-full">
            <MainHeader name="Log Kayıtları"/>
            <div>
                <RenderTableData/>
            </div>
        </div>
    );
}
