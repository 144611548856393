import BaseCard from "../../../../components/cards/BaseCard";
import CustomGaugeChart from "../../../../components/gauge/CustomGaugeChart";
import React from "react";

export default function SpeedPart (props){
    const {data} = props

    return (
        <div className="flex-1 flex flex-row flex-wrap my-5">
            {
                data && Array.isArray(data) ?
                    data.map((card, key) =>
                        <div key={key} className="w-1/2 lg:w-1/3 xl:w-1/5">
                            <div className="mx-2">
                                <BaseCard title={card.mainGenreName}>
                                    <CustomGaugeChart value={card.gamePointAVG / 100}/>
                                </BaseCard>
                            </div>
                        </div>
                    )

                    : null
            }
        </div>
    )
}