import BaseCard from "../../../../components/cards/BaseCard";
import {Line} from "react-chartjs-2";
import React from "react";

export default function UsagePart (props){
    const {evolution} = props


    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Kullanıcı Gelişimi",
            },
        },
    };


    const calculateEvolutionData = (genre) => {

        const result = {
            labels: evolution.monthNames,
            datasets: [],
        }
        const totalData = []
        for (let i = 0; i < evolution.totalEvolution.length; i++) {
            totalData.push(evolution.totalEvolution[i][genre] ? evolution.totalEvolution[i][genre] : 0)
        }
        const studentData = []
        for (let i = 0; i < evolution.studentEvolution.length; i++) {
            studentData.push(evolution.studentEvolution[i][genre] ? evolution.studentEvolution[i][genre] : 0)
        }

        const schoolData = []
        for (let i = 0; i < evolution.schoolEvolution.length; i++) {
            schoolData.push(evolution.schoolEvolution[i][genre] ? evolution.schoolEvolution[i][genre] : 0)
        }
        const dataStudent = {
            label: "Öğrenci",
            data: studentData,
            borderColor: "rgb(218, 118, 19, 1)",
            backgroundColor: "rgba(218, 118, 19, 0.5)",
        }
        const dataSchool = {
            label: "Okul Ortalaması",
            data: schoolData,
            borderColor: "rgb(229, 212, 206, 1)",
            backgroundColor: "rgb (229, 212, 206, 0.5)",
        }
        const dataGeneral = {
            label: "Genel Ortalama",
            data: totalData,
            borderColor: "rgb(230, 99, 99, 1)",
            backgroundColor: "rgba(230, 99, 99, 0.5)",
        }
        result.datasets.push(dataGeneral)
        if (schoolData.length > 0) {
            result.datasets.push(dataSchool)
        }
        if (studentData.length > 0) {
            result.datasets.push(dataStudent)
        }
        return result
    }

    return (
        <div className="flex-1 flex flex-row flex-wrap my-5">


            {

                evolution ?
                    evolution.mainGenres.map((genre, key) =>
                        <div key={key} className="w-full lg:w-1/2">
                            <div className="mx-2">
                                <BaseCard title={genre.name}>
                                    <Line options={lineOptions}
                                          data={calculateEvolutionData(genre.name)}/>
                                </BaseCard>
                            </div>
                        </div>
                    )

                    : null
            }


        </div>
    )

}