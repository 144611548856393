export const calculateAVGData = (data, type) => {
    const countData = {
        labels: [],
        datasets: [
            {
                label: "Başarı %'si ",
                data: [],
                backgroundColor: [
                    "rgba(230, 99, 99, 1)",
                    "rgba(229, 212, 206, 1)",
                    "rgba(218, 118, 19, 1)",
                    "rgba(127, 209, 185, 1)",
                    "rgba(122, 101, 99, 1)",
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };



    if (data) {
        for (let i = 0; i < data.length; i++) {
            countData.labels.push(data[i].mainGenreName);
            if (type === "avg") {
                countData.datasets[0].data.push(data[i].gamePointAVG);
                countData.datasets[0].label = "Başarı %'si "
            }
            if (type === "count") {
                countData.datasets[0].data.push(data[i].gamePointSUM);
                countData.datasets[0].label = "Toplam Puan "
            }
        }
    }

    return countData;
};
