import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import BaseModal from "../../../components/modals/BaseModal";
import CreateOrUpdateHomework from "../../../components/institution/homework/CreateOrUpdateHomework";
import {
    getTeacherBranchList,
    retrieveBranchListByInstitutionIdSeasonIdGradeId,
    retrieveHomeworkListByBranchId,
    retrieveTreeList,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {constructGradeOptionList} from "../../../utilization/InstitutionOperations";
import InputGroup from "../../../components/inputs/InputGroup";
import {useAuth} from "../../../context/UserContext";
import HomeworkCheck from "./HomeworkCheck";
import SelectGrade from "../../../components/SelectGrade";
import ApproveButton from "../../../components/buttons/ApproveButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

export default function InstitutionHomeworkScreen(props) {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();


    const {getBranch} = props;

    const {institutionId, userInformation} = useAuth();
    const [homeworkList, setHomeworkList] = useState([]);
    const [treeList, setTreeList] = useState([]);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [branchList, setBranchList] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedHomework, setSelectedHomework] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [branchSize, setBranchSize] = useState(0);
    const [teacherPage, setTeacherPage] = useState(false);


    const retrieveAndSetTeacherBranchList = async () => {
        const {data, err} =
            await getTeacherBranchList(
                userInformation.id
            );
        if (err) {
            toast("Şube listesi gelirken bir hata oluştu");
            return;
        }
        setBranchList(data);
        if (data.length > 0) {
            setSelectedBranchId(data[0].id)
            setBranchSize(data.length)
        }
    };

    useEffect(() => {
        if (userInformation.role === "TEACHER") {
            setTeacherPage(true);
            retrieveAndSetTeacherBranchList()
        }

    }, []);


    useEffect(() => {

        const retrieveAndSetTreeList = async () => {
            const {data, err} = await retrieveTreeList();
            if (err) {
                toast("Ağaç listesi gelirken bir hata oluştu");
                return;
            }
            setTreeList(data);
        };
        retrieveAndSetTreeList();
    }, []);

    useEffect(() => {
        const gradeList = constructGradeOptionList();
        if (gradeList.length > 0) {
            setSelectedGradeId(gradeList[0].value);
        }
    }, []);

    useEffect(() => {
        if (!selectedGradeId) return;
        retrieveAndSetBranchList();
    }, [selectedGradeId]);

    useEffect(() => {
        if (!selectedBranchId) return;
        retrieveAndSetHomeworkList();
    }, [selectedBranchId]);

    const retrieveAndSetHomeworkList = async () => {
        const {data, err} = await retrieveHomeworkListByBranchId(
            selectedBranchId
        );
        if (err) {
            toast("Görev listesi sorgulanırken bir hata oluştu");
            return;
        }
        setHomeworkList(data);
    };

    const retrieveAndSetBranchList = async () => {
        const {data, err} =
            await retrieveBranchListByInstitutionIdSeasonIdGradeId(
                institutionId,
                selectedGradeId
            );
        if (err) {
            toast("Şube listesi gelirken bir hata oluştu");
            return;
        }
        setBranchList(data);
        if (data.length > 0) {
            setSelectedBranchId(data[0]["id"]);
        }
    };


    const handleAfterCreateOrUpdate = () => {
        retrieveAndSetHomeworkList();
        setShowModal(false);
    };

    const RenderCreateOrUpdateHomeworkModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateHomework
                    initialHomework={selectedHomework}
                    treeList={treeList}
                    branchId={selectedBranchId}
                    schoolStaffId={userInformation.id}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };


    const RenderTableData = () => {
        return (

            <>
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title="Oluştur"
                            onClick={() => {
                                setSelectedHomework(null)
                                setShowModal(true);
                            }}
                        />
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Ağaç</th>
                        <th scope="col" className="px-6 py-3"> Başlama</th>
                        <th scope="col" className="px-6 py-3"> Bitiş</th>
                        <th scope="col" className="px-6 py-3"> Durum</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        homeworkList.homeworkList ?
                            homeworkList.homeworkList.map((data, key) => (
                                <tr key={key} className="bg-white border-b">
                                    <td className={`px-6 py-4`}>{data.homework.treeId.name}</td>
                                    <td className={`px-6 py-4`}>{data.homework.beginAt}</td>
                                    <td className={`px-6 py-4`}>{data.homework.endAt}</td>
                                    <td className={`px-6 py-4`}>{data.homework.state}</td>
                                    <td>
                                        <PrimaryButton
                                            title={language.update}
                                            onClick={() => {
                                                setShowModal(true);
                                                setSelectedHomework(data.homework);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <PrimaryButton
                                            title={language.report}
                                            onClick={() => {
                                                setShowReport(true);
                                                setSelectedIndex(key);
                                            }}
                                        />
                                    </td>
                                </tr>
                            )) : null
                    }
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <div className="w-full">
            {RenderCreateOrUpdateHomeworkModal()}
            <MainHeader name="Görevler" setShowReport={setShowReport}/>


            <div className="w-full flex flex-row flex-wrap">
                {
                    teacherPage ? null
                        :
                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <div className="lg:ml-2">
                                <SelectGrade value={selectedGradeId} onChange={setSelectedGradeId}/>
                            </div>
                        </div>
                }
                {
                    branchSize > 1 ?
                        <div className="w-full lg:w-1/2 xl:w-1/2">
                            <div className="lg:ml-2">
                                <InputGroup
                                    type={"select"}
                                    optionList={branchList}
                                    value={selectedBranchId}
                                    onChange={(e) => {
                                        setShowReport(false)
                                        setSelectedBranchId(e.target.value);
                                    }}
                                />
                            </div>
                        </div> : null
                }

            </div>

            <div>{
                showReport ?
                    <HomeworkCheck setShowReport={setShowReport} selectedIndex={selectedIndex} homeworkList={homeworkList}/>
                    :
                    <RenderTableData/>
            }

            </div>
        </div>
    );
}
