export const constructGradeOptionList = () => {
  return [
    {
      name: "Ana sınıfı",
      id: "GRADE_PRE_SCHOOL",
    },
    {
      name: "1. Sınıf",
      id: "GRADE_1",
    },
    {
      name: "2. Sınıf",
      id: "GRADE_2",
    },
    {
      name: "3. Sınıf",
      id: "GRADE_3",
    },
    {
      name: "4. Sınıf",
      id: "GRADE_4",
    },
  ];
};
