import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {retrieveLastLogin, retrieveSchoolList} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import BaseModal from "../../../components/modals/BaseModal";
import {useAuth} from "../../../context/UserContext";
import CreateOrUpdateInstitution from "../../../components/institution/CreateOrUpdateInstitution";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {SchoolReport} from "./SchoolReport";
import {useApp} from "../../../context/AppContext";
import {Link} from "react-router-dom";

const language = require("../../../context/language.json")

export default function InstitutionList() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();
    const [institutionList, setInstitutionList] = useState([]);
    const {institutionId} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [showReport, setShowReport] = useState(false);

    const [lastLoginList, setLastLoginList] = useState([]);

    const retrieveAndSetInstitutionList = async () => {
        const {data, err} = await retrieveSchoolList();
        if (err) {
            toast(language.schoolGetError);
            return;
        }
        setInstitutionList(data)
    };


    const getLastLogin = async () => {
        const {data, err} = await retrieveLastLogin();
        if (err) {
            toast(language.schoolGetError);
            return;
        }
        setLastLoginList(data)
    };

    useEffect(() => {
        getLastLogin()
        retrieveAndSetInstitutionList();

    }, []);

    const handleAfterCreateOrUpdate = () => {
        setShowModal(false);
        retrieveAndSetInstitutionList();
    };

    const retrieveInstitutionList = () => {
        return institutionList.filter(i => i.baseSchool === null)
    };


    const findLastLoginDate = (id) => {
        const findLogin = lastLoginList.find(i => i.id === id);
        if (findLogin)
            return findLogin.before
        //return new Date(findLogin.gameAt * 1000).toDateString()
        return "-"
    }

    const RenderTableData = () => {
        return (
            <>

                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title="Oluştur"
                            onClick={() => {
                                setShowModal(true);
                            }}
                        />
                    </div>
                </div>


                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> AD</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {retrieveInstitutionList().map((data, key) => (
                        <>
                            <tr key={key} className="bg-white border-b">
                                <td className={`px-6 py-4`}><b>{data.name}</b></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <PrimaryButton
                                        title={language.update}
                                        onClick={() => {
                                            setShowModal(true);
                                            setSelectedInstitution(data);
                                        }}
                                    />
                                </td>
                                <td>
                                    <PrimaryButton
                                        title={language.report}
                                        onClick={() => {
                                            setShowReport(true);
                                            setSelectedInstitution(data);
                                        }}
                                    />
                                </td>

                            </tr>
                            {institutionList.map((brand, key) => (
                                brand.baseSchool && brand.baseSchool.id === data.id ?
                                    <>
                                        <tr key={key} className="bg-white border-b">
                                            <td className={`px-12 py-4`}><b>{brand.name}</b></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <PrimaryButton
                                                    title={language.update}
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setSelectedInstitution(brand);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <PrimaryButton
                                                    title={language.report}
                                                    onClick={() => {
                                                        setShowReport(true);
                                                        setSelectedInstitution(brand);
                                                    }}
                                                />
                                            </td>

                                        </tr>

                                        {institutionList.map((campus, key) => (
                                            campus.baseSchool && campus.baseSchool.id === brand.id ?
                                                <>
                                                    <tr key={key} className="bg-white border-b">
                                                        <td className={`px-24 py-4`}>* {campus.name}</td>
                                                        <td>{campus.beginAt && campus.beginAt !== "" ? new Date(campus.beginAt).toDateString("tr") : null}</td>
                                                        <td>{campus.endAt && campus.endAt !== "" ? new Date(campus.endAt).toDateString() : null}</td>
                                                        <th>{findLastLoginDate(campus.id)}</th>
                                                        <td>
                                                            <PrimaryButton
                                                                title={language.update}
                                                                onClick={() => {
                                                                    setShowModal(true);
                                                                    setSelectedInstitution(campus);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <PrimaryButton
                                                                title={language.report}
                                                                onClick={() => {
                                                                    setShowReport(true);
                                                                    setSelectedInstitution(campus);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </>
                                                : null
                                        ))}
                                    </>
                                    : null
                            ))}
                        </>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }


    const RenderCreateOrUpdateInstitutionModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateInstitution
                    initialInstitution={selectedInstitution}
                    institutionList={institutionList}
                    institutionId={institutionId}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };
    return (
        <>
            {RenderCreateOrUpdateInstitutionModal()}
            <div className="w-full">
                <MainHeader name={language.schools} setShowReport={setShowReport}/>
                <div>
                    {
                        showReport ?
                            <SchoolReport selectedInstitution={selectedInstitution} setShowReport={setShowReport}/> :
                            <RenderTableData/>
                    }
                </div>
            </div>
        </>
    );
}
