import InputGroup from "./inputs/InputGroup";
import React, {useEffect} from "react";
import {constructGradeOptionList} from "../utilization/InstitutionOperations";

export default function SelectGrade (props){
    const {value, onChange} = props
    return(
        <InputGroup
            type="select"
            optionList={constructGradeOptionList()}
            value={value}
            onChange={(e) => {
                onChange(e.target.value);
            }}
        />
    )
}