import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import MainHeader from "../../../components/headers/MainHeader";
import MiniCountCard from "../../../components/cards/MiniCountCard";
import BaseCard from "../../../components/cards/BaseCard";
import {useApp} from "../../../context/AppContext";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const DashboardScreen = (props) => {
  const {
    setAppState,
    setTheme,
    setPageList,
    _setSelectedStudent,
    _setSelectedTeacher,
    _setSelectedBranch,
    _setSelectedSchool,
    setRole,
    appState,
    theme,
    pageList,
    _selectedSchool,
    _selectedBranch,
    _electedTeacher,
    _selectedStudent,
    role
  } = useApp();

  const doughnutData = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Some line chart",
      },
    },
  };
  const lineLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        label: "Dataset 1",
        data: [100, 200, -300, 400, -500, 600, 700],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Dataset 2",
        data: [1100, -1200, 1300, -1400, 1500, -1600, 700],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="w-full">
      <MainHeader name="Ana sayfa" />
      <div className="flex flex-row flex-wrap gap-y-4">
        <div className="w-1/4 flex flex-col">
          <div className="mx-4 flex flex-col">
            <MiniCountCard
              title="Toplam rapor"
              count={10}
              icon={null}
              backgroundColor={"bg-orange-400"}
              borderColor={"border-orange-400"}
            />
          </div>
        </div>
        <div className="w-1/4 flex flex-col">
          <div className="mx-4 flex flex-col">
            <MiniCountCard
              title="Toplam rapor"
              count={10}
              icon={null}
              backgroundColor={"bg-red-400"}
              borderColor={"border-red-400"}
            />
          </div>
        </div>
        <div className="w-1/4 flex flex-col">
          <div className="mx-4 flex flex-col">
            <MiniCountCard
              title="Toplam rapor"
              count={10}
              icon={null}
              backgroundColor={"bg-purple-400"}
              borderColor={"border-purple-400"}
            />
          </div>
        </div>
        <div className="w-1/4 flex flex-col">
          <div className="mx-4 flex flex-col">
            <MiniCountCard
              title="Toplam rapor"
              count={10}
              icon={null}
              backgroundColor={"bg-green-400"}
              borderColor={"border-green-400"}
            />
          </div>
        </div>
      </div>

      <div className="flex-1 flex flex-row my-5 gap-x-2">
        <div className="w-1/3">
          <BaseCard title="Doughnut">
            <Doughnut data={doughnutData} />
          </BaseCard>
        </div>
        <div className="w-2/3">
          <BaseCard title="Line chart">
            <Line options={lineOptions} data={lineData} />
          </BaseCard>
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
