import MiniCountCard from "../../../../components/cards/MiniCountCard";
import React from "react";

export default function BranchStatistics (props){
    const {mostGame, mostUser} = props

    return (
        <div className="flex flex-row flex-wrap gap-y-4">
            <div className="w-1/2 lg:w-1/3 xl:w-1/2 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="En Çok Oynanan Oyun"
                        count={`${mostGame.game} - ${mostGame.count} kez oynandı.`}
                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                    />
                </div>
            </div>
            <div className="w-1/2 lg:w-1/3 xl:w-1/2 flex flex-col">
                <div className="mx-4 flex flex-col">
                    <MiniCountCard
                        title="En Başarılı Öğrenci"
                        count={`${mostUser.name} ${mostUser.lastname} - ${mostUser.points} puan`}

                        icon={null}
                        backgroundColor={"bg-blue-300"}
                        borderColor={"border-blue-300"}
                    />
                </div>
            </div>


        </div>
    )
}