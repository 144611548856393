import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import MainHeader from "../../../components/headers/MainHeader";
import MiniCountCard from "../../../components/cards/MiniCountCard";
import React, {useContext, useEffect, useState} from "react";
import {useAuth, UserContext} from "../../../context/UserContext";
import {
    getUserStatisticsCount,
    retrieveAllGameData, retrieveEvolution, retrieveEvolutionCount,
} from "../../../requests/ApiOperitions";
import BaseTable from "../../../components/tables/BaseTable";
import {toast} from "react-toastify";
import {calculateAVGData} from "./calculate/CalculateData";
import EvolutionPart from "./reportParts/EvolutionPart";
import UsagePart from "./reportParts/UsagePart";
import SpeedPart from "./reportParts/SpeedPart";
import UsageCountPart from "./reportParts/UsageCountPart";
import DonutPart from "./reportParts/DonutPart";
import language from "../../../context/language.json";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const DemoReport = (props) => {
    const userContext = useContext(UserContext);
    const {userInformation} = userContext;
    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);
    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);
    const {type, selectedInstitution, selectedBranch, selectedStudent, selectedAdmin} = props;
    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [userStatistics, setUserStatistics] = useState(null);
    const {institutionId} = useAuth();
    const [data, setData] = useState(null);
    const retrieveGameData = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }
        const {data, err} = await retrieveAllGameData(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);
        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);

        setData(data.scoreTotalList)
        setUsageCount(data)
    };

    const retrieveGameEvolution = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }

        const {data, err} = await retrieveEvolution(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }
        const {data, err} = await retrieveEvolutionCount(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    const retrieveUserStatisticsCount = async () => {
        const {data, err} = await getUserStatisticsCount();
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setUserStatistics(data)
    };

    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
    }, []);

    const calculateSchoolNumbers = () => {
        return (
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Rol</th>
                        <th scope="col" className="px-6 py-3"> Sınıf</th>
                        <th scope="col" className="px-6 py-3"> Adet</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        userStatistics ?
                            userStatistics.map((data, key) => (
                                <tr key={key} className="bg-white border-b">
                                    <td className={`px-6 py-4`}>{data.role}</td>
                                    <td className={`px-6 py-4`}>{data.ageGroup.name}</td>
                                    <td className={`px-6 py-4`}>{data.count}</td>
                                </tr>
                            )) : null}
                    </tbody>
                </table>
            </div>
        );
    }

    const studentHomeworkList = () => {
        return (
            <div className="flex flex-row flex-wrap gap-y-4">
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                    <div className="mx-4 flex flex-col">
                        <MiniCountCard
                            title="Henüz başlanmamış oyunlar"
                            textList={["Ağaç 9", "Ağaç 10", "Ağaç 11"]}
                            icon={null}
                            backgroundColor={"bg-red-400"}
                            borderColor={"border-red-400"}
                        />
                    </div>
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                    <div className="mx-4 flex flex-col">
                        <MiniCountCard
                            title="Devam eden oyunlar"
                            textList={["-"]}
                            icon={null}
                            backgroundColor={"bg-purple-400"}
                            borderColor={"border-purple-400"}
                        />
                    </div>
                </div>
                <div className="w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col">
                    <div className="mx-4 flex flex-col">
                        <MiniCountCard
                            title="Tamamlanmış oyunlar"
                            textList={["-"]}
                            icon={null}
                            backgroundColor={"bg-green-400"}
                            borderColor={"border-green-400"}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full">

            {
                type === "student" ? studentHomeworkList() : null
            }
            <div className="mt-12"/>
            {
                calculateSchoolNumbers()
            }

            <MainHeader name={language.reportSkillsRatio}/>
            {countData && avgData ? (
                <DonutPart countData={countData} avgData={avgData}  countDataAll={countDataAll} avgDataAll={avgDataAll}/>
            ) : null}
            {

                type === "student" ?
                    <div>
                        Görsel öğrenme stilinde öğrenmenin en etkili yolu görerek, gözlem yaparak
                        ve okuyarak öğrenmedir. Görsel öğrenme stilinin baskın olduğu kişiler
                        görsel hafızası daha güçlü olan kişilerdir. Öğrendiklerini hatırlarken
                        görsel kareler şeklinde anımsarlar. Öğrenmenin daha etkili hale gelmesi
                        için görsel öğrenme stiline uygun eğitim sunumları, videolar, resimler,
                        tablolar, zihin haritaları gibi görselliğin baskın olduğu eğitim
                        materyalleri kullanılmalıdır.
                        <br/>
                        <br/>
                        Görsel öğrenme stiline sahip bireyler kişisel yaşamlarında düzene önem
                        veren, planlı, programlı olmaktan hoşlanan kişilerdir. Bu kişisel çalışma
                        alanlarının derli toplu olmasını isterler. Çalışma alanları temiz,
                        havadar, aydınlık ve düzenlidir. Dikkatlerinin dağılmaması için çalışma
                        konuları dışında araç-gereç, ders notu, kitap vb. bulunmasından
                        hoşlanmazlar. Masaları gibi okul çantalarının da toplu olmasını,
                        çantalarında aradıkları bir şeyi hızlıca bulabilmeyi isterler.
                        Kitaplıkları, masaları, çantaları için düzenleyici aparatlar kullanarak
                        eşyalarını kendi içlerinde ayrıştırırlar. Defter ve kitapları temiz, zarar
                        görmemiştir ve yazıları bilgisayarda yazılmışçasına düzenlidir. Düzen
                        kadar kuralları da severler. Planlı ders çalışır, işlerini kolay kolay
                        ertelemezler. Bu öğrenciler aynı zamanda dil bilgisi kurallarına karşı da
                        hassastır ve yazım dillerine ve konuşma şekillerine önem verirler.
                    </div>
                    : null

            }
            <div className="mt-12"/>
            <MainHeader name={language.reportUsageRatio}/>
            <UsageCountPart data={usageCount}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportCognitive}/>
            <SpeedPart data={data}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportIntelligence}/>
            <EvolutionPart evolution={evolution}/>
            <div className="mt-12"/>
            <MainHeader name="Kullanım gelişimi"/>
            <UsagePart evolution={evolutionCount}/>

        </div>
    );
};
