import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import MainHeader from "../../../components/headers/MainHeader";
import React, {useEffect, useState} from "react";
import {
    getUserStatisticsCount,
    retrieveAllGameData, retrieveEvolution, retrieveEvolutionCount, retrieveHomeworkListByStudentId,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {calculateAVGData} from "./calculate/CalculateData";
import EvolutionPart from "./reportParts/EvolutionPart";
import UsagePart from "./reportParts/UsagePart";
import SpeedPart from "./reportParts/SpeedPart";
import UsageCountPart from "./reportParts/UsageCountPart";
import DonutPart from "./reportParts/DonutPart";
import StudentHomeworkPart from "./reportParts/StudentHomeworkPart";
import CommentPart from "./reportParts/CommentPart";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const StudentReport = (props) => {

    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();


    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);
    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);
    const {setShowReport, selectedStudent, branchId} = props;
    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [data, setData] = useState(null);
    const [studentHomework, setStudentHomework] = useState(null);

    const [commandText, setCommandText] = useState("");

    const retrieveGameData = async () => {

        const {data, err} = await retrieveAllGameData("student", selectedStudent.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }

        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);

        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);

        setData(data.scoreTotalList)
        setUsageCount(data)
        setCommandText(data.commandText)
    };


    const retrieveHomeworkListBy = async () => {

        const {data, err} = await retrieveHomeworkListByStudentId(branchId, selectedStudent.id);
        if (err) {
           // toast(language.reportGetData);
            return;
        }
        setStudentHomework(data)
    };

    const retrieveGameEvolution = async () => {
        const {data, err} = await retrieveEvolution("student", selectedStudent.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        const {data, err} = await retrieveEvolutionCount("student", selectedStudent.id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    useEffect(() => {
        retrieveHomeworkListBy()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
    }, []);


    function printdiv() {
        var header_str = '<html><head><title>' + document.title + '</title></head><body>';
        var footer_str = '</body></html>';
        var new_str = document.getElementById("printable_div").innerHTML;
        var old_str = document.body.innerHTML;
        document.body.innerHTML = header_str + new_str + footer_str;
        window.print();
        document.body.innerHTML = old_str;
        return false;
    }


    return (
        <div className="w-full">
            <div className="my-2">
                <div className="flex flex-row justify-end">
                    <ApproveButton
                        title="GERİ"
                        onClick={() => {
                            setShowReport(false)
                        }}
                    />
                </div>
            </div>

            <div id="printable_div">
                <div className="mt-12"/>
                <MainHeader name={language.reportSkillsRatio}/>
                {countData && avgData ? (
                    <DonutPart countData={countData} avgData={avgData} countDataAll={countDataAll}
                               avgDataAll={avgDataAll}/>
                ) : null}
                <CommentPart commandText={commandText}/>
                <div className="mt-12"/>
                <MainHeader name="Görevler"/>
                <StudentHomeworkPart studentHomework={studentHomework}/>
                <MainHeader name={language.reportUsageRatio}/>
                <UsageCountPart data={usageCount}/>
                <div className="mt-12"/>
                <MainHeader name={language.reportCognitive}/>
                <SpeedPart data={data}/>
                <div className="mt-12"/>
                <MainHeader name={language.reportIntelligence}/>
                <EvolutionPart evolution={evolution}/>
                <div className="mt-12"/>
                <MainHeader name="Kullanım gelişimi"/>
                <UsagePart evolution={evolutionCount}/>
            </div>
        </div>
    );
};
