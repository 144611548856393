import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import MainHeader from "../../../components/headers/MainHeader";
import React, {useContext, useEffect, useState} from "react";
import {
    getSchoolUserStatisticsCount,
    retrieveAllGameData,
    retrieveEvolution,
    retrieveEvolutionCount,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {useAuth, UserContext} from "../../../context/UserContext";
import {calculateAVGData} from "../page/calculate/CalculateData";
import DonutPart from "../page/reportParts/DonutPart";
import UsageCountPart from "../page/reportParts/UsageCountPart";
import SpeedPart from "../page/reportParts/SpeedPart";
import EvolutionPart from "../page/reportParts/EvolutionPart";
import UsagePart from "../page/reportParts/UsagePart";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";
import UserCountPart from "../page/reportParts/UserCountPart";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const DashboardScreen = () => {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();


    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);

    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);

    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [userStatistics, setUserStatistics] = useState(null);
    const {institutionId} = useAuth();
    const [data, setData] = useState(null);
    const retrieveGameData = async () => {


        const {data, err} = await retrieveAllGameData("school", institutionId);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);


        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);

        setData(data.scoreTotalList)
        setUsageCount(data)
    };


    const retrieveGameEvolution = async () => {
        const {data, err} = await retrieveEvolution("school", institutionId);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        const {data, err} = await retrieveEvolutionCount("school", institutionId);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    const retrieveUserStatisticsCount = async () => {
        const {data, err} = await getSchoolUserStatisticsCount(institutionId);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setUserStatistics(data)
    };

    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
    }, []);

    useEffect(() => {

        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();

    }, [institutionId]);


    return (
        <div className="w-full">
            <div className="mt-12"/>
            <MainHeader name={language.reportUsageRatio}/>
            <UserCountPart userStatistics={userStatistics}/>
            <MainHeader name={language.reportSkillsRatio}/>
            {countData && avgData ? (
                <DonutPart countData={countData} avgData={avgData} countDataAll={countDataAll} avgDataAll={avgDataAll}/>
            ) : null}
            <div className="mt-12"/>
            <MainHeader name={language.reportUsageRatio}/>
            <UsageCountPart data={usageCount}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportCognitive}/>
            <SpeedPart data={data}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportIntelligence}/>
            <EvolutionPart evolution={evolution}/>
            <div className="mt-12"/>
            <MainHeader name="Kullanım gelişimi"/>
            <UsagePart evolution={evolutionCount}/>

        </div>
    );
};
export default DashboardScreen;
