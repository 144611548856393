import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {retrieveStaffList} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import BaseModal from "../../../components/modals/BaseModal";
import {useAuth} from "../../../context/UserContext";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import CreateOrUpdateInstitutionStaff from "../../../components/institution/staffs/CreateOrUpdateInstitutionStaff";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

export default function InstitutionStaffScreen() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();
    const [staffList, setStaffList] = useState([]);
    const {institutionId} = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);

    useEffect(() => {
        retrieveAndSetStaffList();
    }, []);

    const retrieveAndSetStaffList = async () => {
        const {data, err} = await retrieveStaffList(institutionId);
        if (err) {
            toast("Yöneticiler listesi yüklenirken bir hata oluştu");
            return;
        }
        setStaffList(data);
    };

    const handleAfterCreateOrUpdate = () => {
        setShowModal(false);
        retrieveAndSetStaffList();
    };

    const RenderTableData = () => {
        return (
            <>
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title="Oluştur"
                            onClick={() => {
                                setSelectedStaff(null)
                                setShowModal(true);
                            }}
                        />
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> isim</th>
                        <th scope="col" className="px-6 py-3"> Soyisim</th>
                        <th scope="col" className="px-6 py-3"> Kullanıcı Adı</th>
                        <th scope="col" className="px-6 py-3"> Okul</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {staffList.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.lastname}</td>
                            <td className={`px-6 py-4`}>{data.username}</td>
                            <td className={`px-6 py-4`}>{data.school.name}</td>
                            <td>
                                <PrimaryButton
                                    title={language.update}
                                    onClick={() => {
                                        setSelectedStaff(data);
                                        setShowModal(true);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }

    const RenderCreateOrUpdateInstitutionStaffModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateInstitutionStaff
                    initialStaff={selectedStaff}
                    institutionId={institutionId}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };

    return (
        <>
            {RenderCreateOrUpdateInstitutionStaffModal()}
            <div className="w-full">
                <MainHeader name="Yöneticiler"/>
                <div>
                    <RenderTableData/>
                </div>
            </div>
        </>
    );
}
