import React from "react";
import {useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {useAuth} from "../../../context/UserContext";
import {
    retrieveBranchListByInstitutionIdSeasonIdGradeId,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import BaseModal from "../../../components/modals/BaseModal";
import CreateOrUpdateInstitutionBranch from "../../../components/institution/branch/CreateOrUpdateInstitutionBranch";
import SelectGrade from "../../../components/SelectGrade";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ApproveButton from "../../../components/buttons/ApproveButton";
import {BranchReport} from "./BranchReport.js";
import InstitutionStudentScreen from "./InstitutionStudentScreen";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

export default function InstitutionBranchScreen() {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();
    const {institutionId} = useAuth();
    const [branchList, setBranchList] = useState([]);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [showBranchStudents, setShowBranchStudents] = useState(false);

    const [selectedBranch, setSelectedBranch] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showReport, setShowReport] = useState(false);

    const retrieveAndSetBranchList = async () => {
        const {data, err} =
            await retrieveBranchListByInstitutionIdSeasonIdGradeId(
                institutionId,
                selectedGradeId
            );
        if (err) {
            toast("Şube listesi gelirken bir hata oluştu");
            return;
        }
        setBranchList(data);
    };

    useEffect(() => {
        if (!institutionId) return;
        if (!selectedGradeId) return;
        retrieveAndSetBranchList();
    }, [institutionId, selectedGradeId]);

    useEffect(() => {
        if (!selectedGradeId) return;
        retrieveAndSetBranchList();
    }, [selectedGradeId]);




    const RenderTableData = () => {
        return (
            <>

                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        {
                            selectedGradeId ?
                                <ApproveButton
                                    title={language.insert}
                                    onClick={() => {
                                        setSelectedBranch(null);
                                        setShowModal(true);
                                    }}
                                />
                                :null
                        }

                    </div>
                </div>


                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Şube Adı</th>
                        <th scope="col" className="px-6 py-3"> Okul</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {branchList.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.school.name}</td>
                            <td>
                                <PrimaryButton
                                    title={language.update}
                                    onClick={() => {
                                        setShowModal(true);
                                        setSelectedBranch(data);
                                        setShowBranchStudents(false)
                                    }}
                                />
                            </td>
                            <td>
                                <PrimaryButton
                                    title={language.report}
                                    onClick={(e) => {
                                        setShowReport(true);
                                        setSelectedBranch(data);
                                        setShowBranchStudents(false)
                                    }}
                                />
                            </td>
                            <td>
                                <PrimaryButton
                                    title={"Öğrenciler"}
                                    onClick={(e) => {
                                        setSelectedBranch(data);
                                        setShowBranchStudents(true)
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {
                    showBranchStudents ?   <InstitutionStudentScreen getBranch={selectedBranch}/> : null
                }

            </>
        )
    }



    const handleAfterCreateOrUpdate = () => {
        setShowModal(false);
        retrieveAndSetBranchList();
    };

    const RenderCreateOrUpdateInstitutionBranchModal = () => {
        return (
            <BaseModal
                modalIsOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
            >
                <CreateOrUpdateInstitutionBranch
                    initialBranch={selectedBranch}
                    grade={selectedGradeId}
                    schoolId={institutionId}
                    handleAfterCreateOrUpdate={handleAfterCreateOrUpdate}
                />
            </BaseModal>
        );
    };



    return (
        <>
            {RenderCreateOrUpdateInstitutionBranchModal()}
            <div className="w-full">
                <MainHeader name="Şubeler" setShowReport={setShowReport}/>
                <div className="flex flex-row flex-wrap">
                    <div className="w-full lg:w-1/1">
                        <div className="lg:ml-2">
                            <SelectGrade value={selectedGradeId} onChange={setSelectedGradeId}/>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        showReport ? <BranchReport setShowReport={setShowReport} type="branch" selectedBranch={selectedBranch.id}/> : <RenderTableData/>
                    }

                </div>
            </div>
        </>
    );
}
