import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/UserContext";
import {useApp} from "../context/AppContext";
import InputGroup from "../components/inputs/InputGroup";
import {useEffect} from "react";
import language from "../context/language.json";

const LeftPanelNavigation = () => {
    const navigate = useNavigate();
    const {setAppState} = useApp();
    const {
        handleLogout,
        userInformation,
        institutionId,
        setInstitutionId,
        setInstitution,
        institutionList,
        institution
    } = useAuth();


    const [selectedIndex, setSelectedIndex] = useState(0);


    const [mainInstitutions, setMainInstitutions] = useState([]);
    const [brands, setBrands] = useState([]);
    const [schools, setSchools] = useState([]);


    const [selectedMain, setSelectedMain] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);

    useEffect(() => {


        if (!institutionList) return;
        let _mainList = institutionList.filter(i => i.type === "SCHOOL" && i.kind === "MAIN")

        if (userInformation.role === "ADMIN" && userInformation.school.kind === "BRAND") {


            _mainList = []
            const _brandList = institutionList.filter(i => i.id === userInformation.school.id)
            setBrands(_brandList);
        }

        if (userInformation.role === "ADMIN" && userInformation.school.kind === "SCHOOL") {
            _mainList = []
            setBrands([])
            const _schoolList = institutionList.filter(i => i.id === userInformation.school.id)
            setSchools(_schoolList);
        }



        setMainInstitutions(_mainList);
    }, [institutionList]);

// setInstitutionId(e.target.value);


    useEffect(() => {
        if (selectedSchool) {
            setInstitutionId(selectedSchool);
        } else if (selectedBrand) {
            setInstitutionId(selectedBrand);
        } else if (selectedMain) {
            setInstitutionId(selectedMain);
        }
        // else setInstitutionId(0);
    }, [selectedMain, selectedBrand, selectedSchool]);

    useEffect(() => {
        if (institutionId) {
            const _selectedInstitution = institutionList.find(i => Number(i.id) === Number(institutionId));
            if (_selectedInstitution) setInstitution(_selectedInstitution);
        }
        ;

    }, [institutionId]);


    const selectInstitutionEvent = (type, value) => {

        if (type === "main") {
            setSelectedMain(value)
            setSelectedSchool(null)
            setSelectedBrand(null)
            if (value === "SEÇİNİZ") {
                setSelectedMain(null)
                return
            }
            const _mainList = institutionList.filter(i => Number(i.baseSchool?.id) === Number(value))
            setBrands(_mainList);

        } else if (type === "brand") {
            setSelectedBrand(value)
            setSelectedSchool(null)
            if (value === "SEÇİNİZ") {
                setSelectedBrand(null)
                return
            }
            const _mainList = institutionList.filter(i => Number(i.baseSchool?.id) === Number(value))
            setSchools(_mainList);
        } else {
            setSelectedSchool(value)
            if (value === "SEÇİNİZ") {
                setSelectedSchool(null)
                return
            }
        }


        setInstitutionId(value);
        setSelectedIndex(0);
        navigate("/");
    }


    useEffect(() => {
        if (!institutionId) return;
        const findSchool = institutionList.find(i => Number(i.id) === Number(institutionId));
        setInstitution(findSchool);
    }, [institutionId]);

    useEffect(() => {
        if (!institutionId) setInstitutionId(0);
    }, []);


    useEffect(() => {
        setSelectedMain(0)
        const findSchool = institutionList.find(i => Number(i.id) === 0);
        setInstitution(findSchool);
    }, []);


    const navigateStudentList = [
        {
            index: 0,
            name: language.mainPage,
            path: "/",
        }

    ];


    const navigateList = [
        {
            index: 0,
            name: language.mainPage,
            path: "/",
        },
        {
            index: 2,
            name: language.students,
            path: "/students",
        },
        {
            index: 3,
            name: language.teachers,
            path: "/teachers",
        },

        {
            index: 4,
            name: language.tasks,
            path: "/homeworks",
        },
        {
            index: 5,
            name: language.branches,
            path: "/branches",
        },

    ];


    const navigateTeacherList = [
        {
            index: 0,
            name: language.mainPage,
            path: "/",
        },
        {
            index: 1,
            name: language.tasks,
            path: "/homeworks",
        },

    ];
    const navigateSuperList = [
        {
            index: 0,
            name: language.mainPage,
            path: "/",
        },
        {
            index: 1,
            name: language.schools,
            path: "/institutions",
        },
        {
            index: 2,
            name: language.branches,
            path: "/branches",
        },
        {
            index: 3,
            name: language.students,
            path: "/students",
        },
        {
            index: 4,
            name: language.teachers,
            path: "/teachers",
        },
        {
            index: 5,
            name: language.staffs,
            path: "/staffs",
        },
        {
            index: 6,
            name: language.individual,
            path: "/individual",
        },
        {
            index: 7,
            name: language.comments,
            path: "/comments",
        },
        {
            index: 8,
            name: language.trees,
            path: "/trees",
        },




    ];

    /*
    ,

        {
            index: 9,
            name: "DENEME",
            path: "/password",
        },
     */

    useEffect(() => {
        if (!userInformation) return;
        if (
            userInformation.role === "SUPER_ADMIN" ||
            userInformation.role === "ADMIN"
        ) {
            let currentNavigateList;
            if (userInformation.role === "SUPER_ADMIN") {
                currentNavigateList = navigateSuperList;
            } else if (userInformation.role === "ADMIN") {
                currentNavigateList = navigateList;
            }
            const pathname = window.location.pathname;
            const foundNavigate = currentNavigateList.find(
                (item) => item.path === pathname
            );
            if (foundNavigate) {
                setSelectedIndex(foundNavigate.index);
            } else {
                window.location.href = "/";
            }
        }
    }, [window.location.href, userInformation]);

    const RenderHeaderItem = (key, name, index, navigateTo) => {
        return (
            <div
                key={key}
                className={`${
                    index === selectedIndex
                        ? "bg-left-panel-selected text-white font-bold"
                        : ""
                } px-4 py-2 transition-all duration-500 cursor-pointer rounded-xl my-2`}
                onClick={() => {
                    navigate(navigateTo);
                }}
            >
                <p>{name}</p>
            </div>
        );
    };


    const RenderLeftPanelOptionList = () => {
        if (!userInformation) return;
        switch (userInformation.role) {
            case "SUPER_ADMIN":
                return (
                    <div className="flex flex-col">
                        {navigateSuperList.map((navigate, key) =>
                            RenderHeaderItem(
                                key,
                                navigate.name,
                                navigate.index,
                                navigate.path
                            )
                        )}
                        {/* {RenderHeaderItem("Loglar", 7, "/logs")} */}
                    </div>
                );
            case "ADMIN":
                return (
                    <div className="flex flex-col">
                        {navigateList.map((navigate, key) =>
                            RenderHeaderItem(
                                key,
                                navigate.name,
                                navigate.index,
                                navigate.path
                            )
                        )}
                        {/* {RenderHeaderItem("Loglar", 7, "/logs")} */}
                    </div>
                );
            case "USER":
                return (
                    <div>
                        {navigateStudentList.map((navigate, key) =>
                            RenderHeaderItem(
                                key,
                                navigate.name,
                                navigate.index,
                                navigate.path
                            )
                        )}
                    </div>
                );
            case "TEACHER":
                return (
                    <div className="flex flex-col">
                        {navigateTeacherList.map((navigate, key) =>
                            RenderHeaderItem(
                                key,
                                navigate.name,
                                navigate.index,
                                navigate.path
                            )
                        )}
                    </div>
                );
        }
    };

    return (
        <div className="h-full flex flex-col bg-left-panel text-white min-w-[250px] rounded-l-3xl">
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-col justify-center items-center">
                    <div className="w-[150px] mt-2">
                        <img
                            className="w-full"
                            src="/logos/Miamo4kids-Mindventure.png"
                            alt="Miamo4kids-Mindventure"
                        />
                    </div>
                </div>
                <div className="mt-2">
                    <div className="flex flex-col justify-center items-center">
                        <div>
                            {userInformation.name} {userInformation.lastname}
                        </div>
                        <div>
                            {userInformation.school.name}
                        </div>
                        <div>Hoş geldin!</div>
                    </div>
                </div>
                {(userInformation.role === "SUPER_ADMIN" || userInformation.role === "ADMIN") && institutionList ? (
                    <div className="mt-2">
                        <div className="mx-2">
                            {
                                mainInstitutions.length >0 ?
                                    <InputGroup
                                        type={"select"}
                                        optionList={mainInstitutions}
                                        value={selectedMain}
                                        onChange={(e) => {
                                            selectInstitutionEvent("main", e.target.value);
                                        }}
                                    />
                                    :null
                            }
                            {
                                brands.length>0?
                                    <InputGroup
                                        type={"select"}
                                        optionList={brands}
                                        value={selectedBrand}
                                        onChange={(e) => {
                                            selectInstitutionEvent("brand", e.target.value);
                                        }}
                                    />
                                    :null
                            }
                            {
                                schools.length>0 ?
                                    <InputGroup
                                        type={"select"}
                                        optionList={schools}
                                        value={selectedSchool}
                                        onChange={(e) => {
                                            selectInstitutionEvent("school", e.target.value);
                                        }}
                                    />
                                    :null
                            }



                        </div>
                    </div>
                ) : null}
            </div>
            <div
                className="h-[1px] my-2 w-full flex flex-col justify-center items-center border-b border-b-white"></div>
            <div className="px-4 py-1 my-1 overflow-y-auto">
                <div className="flex-1 flex flex-col ">
                    <div className="">
                        <div className="flex-1 flex flex-col justify-between">
                            {RenderLeftPanelOptionList()}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="h-[1px] my-2 w-full flex flex-col justify-center items-center border-b border-b-white"></div>
            <div className="px-4 py-1 my-1">
                <div
                    className="h-full"
                    onClick={() => {
                        handleLogout();
                        setAppState("LOGIN_REQUIRED");
                        navigate("/");
                    }}
                >
                    {RenderHeaderItem("Çıkış yap", "Çıkış yap", "/")}
                </div>
            </div>
        </div>
    );
};

export default LeftPanelNavigation;
