import { Component, createContext, useContext } from "react";

export const AppContext = createContext();

class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appState: "INITIALIZED",
      theme: "LIGHT",
      pageList:[],
      _selectedSchool:null,
      _selectedBranch:null,
      _selectedTeacher:null,
      _selectedStudent:null,
      role:null,
    };
    this.setAppState = this.setAppState.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this._setSelectedSchool = this._setSelectedSchool.bind(this);
    this._setSelectedBranch = this._setSelectedBranch.bind(this);
    this._setSelectedTeacher = this._setSelectedTeacher.bind(this);
    this._setSelectedStudent = this._setSelectedStudent.bind(this);
    this.setPageList = this.setPageList.bind(this);
    this.setRole = this.setRole.bind(this);
  }
  setRole(role) {
    this.role({ role });
  }
  _setSelectedSchool(school) {
    this._selectedSchool({ school });
  }
  _setSelectedBranch(branch) {
    this._selectedBranch({ branch });
  }
  _setSelectedTeacher(teacher) {
    this._selectedTeacher({ teacher });
  }
  _setSelectedStudent(student) {
    this._selectedStudent({ student });
  }
  setAppState(appState) {
    this.setState({ appState });
  }
  setTheme(theme) {
    this.setState({ theme });
  }
  setPageList(pageList) {
    this.setState({ pageList });
  }
  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setAppState: this.setAppState,
          setTheme: this.setTheme,
          setPageList: this.setPageList,
          _setSelectedStudent: this._setSelectedStudent,
          _setSelectedTeacher: this._setSelectedTeacher,
          _setSelectedBranch: this._setSelectedBranch,
          _setSelectedSchool: this._setSelectedSchool,
          setRole: this.setRole
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) throw Error("Context must be not null.");
  return context;
};

export default AppContextProvider;
