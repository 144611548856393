import GaugeChart from "react-gauge-chart";

const CustomGaugeChart = (props) => {
    const {value, title} = props;
    return (
        <div className="w-full">
            {title ? (
                <div className="flex flex-col justify-center items-center my-4">
                    <p className="text-xl">{title}</p>
                </div>
            ) : null}
            <GaugeChart
                id="gauge-chart2"
                nrOfLevels={20}
                percent={value}
                arcWidth={0.25}
                textColor="#000000"
                colors={["#f87171", "#4ade80"]}
            />
        </div>
    );
};

export default CustomGaugeChart;
