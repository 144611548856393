import BaseCard from "../../../../components/cards/BaseCard";
import {Doughnut, Pie} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import ApproveButton from "../../../../components/buttons/ApproveButton";

export default function DonutPart (props){
    const {countData, avgData, countDataAll, avgDataAll} = props
    const [_countData, setCountData] = useState(countData)
    const [_avgData, setAvgData] = useState(avgData)
    const [type, setType] = useState(true)

    useEffect(() => {

        if(type){
            setCountData(countData)
            setAvgData(avgData)
        }else{
            setCountData(countDataAll)
            setAvgData(avgDataAll)
        }
    }, [type, countData, avgData, countDataAll, avgDataAll]);



    return (
        <div className="flex-1 flex flex-row flex-wrap my-5">

            <div className="w-full xl:w-1/2">
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title={type ? "ALT BECERİLERİ GÖSTER" : "GENEL BECERİLERİ GÖSTER"}
                            onClick={() => {
                                setType(!type)
                            }}
                        />
                    </div>
                </div>
                <div className="mx-2">
                    <BaseCard title="Tüm Alanlarda Toplam Puan">
                        <Pie data={_countData}/>
                    </BaseCard>
                </div>
            </div>
            <div className="w-full xl:w-1/2">
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title={type ? "ALT BECERİLERİ GÖSTER" : "GENEL BECERİLERİ GÖSTER"}
                            onClick={() => {
                                setType(!type)
                            }}
                        />

                    </div>
                </div>
                <div className="mx-2">
                    <BaseCard title="Yapılan çalışma oranı">
                        <Doughnut data={_avgData}/>
                    </BaseCard>
                </div>
            </div>
        </div>
    )
}