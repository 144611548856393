import React, {useContext, useEffect, useState} from "react";
import MainHeader from "../../../components/headers/MainHeader";
import {UserContext} from "../../../context/UserContext";
import {
    addTeacherBranchList, removeTeacherBranchList,
    retrieveBranchListByInstitutionIdSeasonIdGradeId,
    retrieveTeacherBranchList,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import InputGroup from "../../../components/inputs/InputGroup";
import SelectGrade from "../../../components/SelectGrade";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

export default function TeacherBranch(props) {
    const userContext = useContext(UserContext);
    const {institutionId} = userContext;
    const {id} = props;
    const [assignment, setAssignment] = useState([]);
    const [selectedGradeId, setSelectedGradeId] = useState(null);
    const [branchList, setBranchList] = useState([]);
    const [selectedBranchId, setSelectedBranchId] = useState(null);

    useEffect(() => {
        const retrieveAndSetBranchList = async () => {
            const {data, err} =
                await retrieveBranchListByInstitutionIdSeasonIdGradeId(
                    institutionId,
                    selectedGradeId
                );
            if (err) {
                toast("Şube listesi gelirken bir hata oluştu");
                return;
            }
            setBranchList(data);
            if (data.length > 0) {
                setSelectedBranchId(data[0]["id"]);
            }
        };

        if (!selectedGradeId) return;
        retrieveAndSetBranchList();
    }, [selectedGradeId]);


    useEffect(() => {

        retrieveTeacherBranch();
    }, []);


    const retrieveTeacherBranch = async () => {
        if (id) {
            const {data, err} = await retrieveTeacherBranchList(id);
            if (err) {
                toast("Atama listesi gelirken bir hata oluştu", err);
                return;
            }
            setAssignment(data);
        }
    };


    const addTeacherBranch = async () => {
        const body = {
            user: {id: id},
            schoolBranch: {id: selectedBranchId},
        }
        const {data, err} = await addTeacherBranchList(body);
        if (err) {
            toast("Atama yapılırken bir hata oluştu", err);
            return;
        }
        retrieveTeacherBranch()

    };


    const removeTeacherBranch = async (id) => {
        const {data, err} = await removeTeacherBranchList(id);
        if (err) {
            toast("Atama silinirken bir hata oluştu", err);
            return;
        }
        retrieveTeacherBranch()

    };


    const RenderTableData = () => {
        return (

            <>

                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> Şube Adı</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {assignment && Array.isArray(assignment) ?
                        assignment.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.schoolBranch.name} {data.schoolBranch.school.name}</td>
                            <td>
                                <PrimaryButton
                                    title={"SİL"}
                                    onClick={() => {
                                        removeTeacherBranch(data.id)
                                    }}
                                />
                            </td>

                        </tr>
                    )) : null}
                    </tbody>
                </table>
            </>
        )
    }


    const retrieveBranchList = () => {
        const branchListWithSchool = []
        branchList.map(b =>
            branchListWithSchool.push({...b, name: `${b.name} ${b.school.name}`})
        )
        return branchListWithSchool;
    }

    return (
        <>

            <div className="w-full">
                <MainHeader name="Şubeler"/>
                <div className="w-full flex flex-row flex-wrap">

                    <div className="w-full lg:w-1/2 xl:w-1/3">
                        <div className="lg:ml-2">
                            <SelectGrade value={selectedGradeId} onChange={setSelectedGradeId}/>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 xl:w-1/3">
                        <div className="lg:ml-2">
                            <InputGroup
                                type={"select"}
                                optionList={retrieveBranchList()}
                                value={selectedBranchId}
                                onChange={(e) => {
                                    setSelectedBranchId(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 xl:w-1/3">
                        <div className="lg:ml-2">
                            <PrimaryButton
                                title={"EKLE"}
                                onClick={() => {
                                    addTeacherBranch()
                                }}
                            />
                        </div>
                    </div>
                    <RenderTableData/>
                </div>

            </div>
        </>
    );
}
