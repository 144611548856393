import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import MainHeader from "../../../components/headers/MainHeader";
import React, {useEffect, useState} from "react";
import {
    getUserStatisticsCount,
    retrieveAllGameData,
    retrieveEvolution,
    retrieveEvolutionCount, retrieveMostRecords,
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {calculateAVGData} from "./calculate/CalculateData";
import EvolutionPart from "./reportParts/EvolutionPart";
import UsagePart from "./reportParts/UsagePart";
import SpeedPart from "./reportParts/SpeedPart";
import UsageCountPart from "./reportParts/UsageCountPart";
import DonutPart from "./reportParts/DonutPart";
import ApproveButton from "../../../components/buttons/ApproveButton";
import BranchStatistics from "./reportParts/BranchStatistics";
import {useApp} from "../../../context/AppContext";
import language from "../../../context/language.json";

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const BranchReport = (props) => {
    const {
        setAppState,
        setTheme,
        setPageList,
        _setSelectedStudent,
        _setSelectedTeacher,
        _setSelectedBranch,
        _setSelectedSchool,
        setRole,
        appState,
        theme,
        pageList,
        _selectedSchool,
        _selectedBranch,
        _electedTeacher,
        _selectedStudent,
        role
    } = useApp();

    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);
    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);
    const {selectedBranch, setShowReport, hiddenButton} = props;
    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [, setUserStatistics] = useState(null);
    const [data, setData] = useState(null);


    const [mostGame, setMostGame] = useState({});
    const [mostUser, setMostUser] = useState({});


    const retrieveGameData = async () => {
        let dataType = "branch"
        const {data, err} = await retrieveAllGameData(dataType, selectedBranch);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);
        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);
        setData(data.scoreTotalList)
        setUsageCount(data)
    };

    const retrieveGameEvolution = async () => {

        let dataType = "branch"
        const {data, err} = await retrieveEvolution(dataType, selectedBranch);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        let dataType = "branch"
        const {data, err} = await retrieveEvolutionCount(dataType, selectedBranch);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    const retrieveUserStatisticsCount = async () => {
        const {data, err} = await getUserStatisticsCount();
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setUserStatistics(data)
    };


    const retrieveMostRecord = async () => {
        const {data, err} = await retrieveMostRecords(selectedBranch);
        if (err) {
            toast(language.reportGetData);
            return;
        }

        const mostGameData = {
            game: "",
            count: 0
        }


        for (let i = 0; i < data.mostRecordsGame.length; i++) {
            if (data.mostRecordsGame[i].count > mostGameData.count) {

                mostGameData.game = data.mostRecordsGame[i].name
                mostGameData.count = data.mostRecordsGame[i].count
            }
        }

        const mostUserData = {
            name: "",
            lastname: "",
            points: 0
        }

        for (let i = 0; i < data.mostRecordsUser.length; i++) {
            if (data.mostRecordsUser[i].points > mostUserData.points) {
                mostUserData.name = data.mostRecordsUser[i].name
                mostUserData.lastname = data.mostRecordsUser[i].lastname
                mostUserData.points = data.mostRecordsUser[i].points
            }
        }


        setMostGame(mostGameData)
        setMostUser(mostUserData)
    };

    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
        retrieveMostRecord();
    }, []);


    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
        retrieveMostRecord();
    }, [props]);

    return (
        <div className="w-full">
            <div className="flex flex-row justify-end">
                {
                    !hiddenButton ?
                        <ApproveButton
                            title={"GERİ"}
                            onClick={() => {
                                setShowReport(false)
                            }}
                        /> : null
                }

            </div>
            <div className="mt-12"/>

            <div className="mt-12"/>
            <MainHeader name="İstatistikler"/>
            <BranchStatistics mostGame={mostGame} mostUser={mostUser}/>
            <MainHeader name={language.reportUsageRatio}/>

            <UsageCountPart data={usageCount}/>

            <MainHeader name={language.reportSkillsRatio}/>
            {countData && avgData ? (
                <DonutPart countData={countData} avgData={avgData} countDataAll={countDataAll} avgDataAll={avgDataAll}/>
            ) : null}

            <div className="mt-12"/>
            <MainHeader name={language.reportCognitive}/>
            <SpeedPart data={data}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportIntelligence}/>
            <EvolutionPart evolution={evolution}/>
            <div className="mt-12"/>
            <MainHeader name="Kullanım gelişimi"/>
            <UsagePart evolution={evolutionCount}/>
        </div>
    );
};
