/* eslint-disable no-unused-vars */
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {convertToTurkish} from "./LanguageOperations";

export default async function Request(method, url, body) {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    if (method === "get") {
        try {
            const res = await axios.get(url, config);
            return {status: res.status,  data: res.data }
        } catch (err) {
            console.log(`Error: ${err}`);
            if (err.response && err.response.data) {
                NotificationManager.error(convertToTurkish(err.response.data));
            }
            return {status: "ERROR",  err: err }
        }
    } else if (method === "patch") {
        try {
            const res = await axios.patch(url, body, config);
            return {status: res.status,  data: res.data }
        } catch (err) {
            console.log(`Error: ${err}`);
            if (err.response && err.response.data) {
                NotificationManager.error(convertToTurkish(err.response.data));
            }
            return {status: "ERROR",  err: err }
        }
    } else if (method === "delete") {
        try {
            const res = await axios.delete(url, config);
            return {status: res.status,  data: res.data }
        } catch (err) {
            console.log(`Error: ${err}`);
            if (err.response && err.response.data) {
                NotificationManager.error(convertToTurkish(err.response.data));
            }
            return {status: "ERROR",  err: err }
        }
    } else if (method === "post") {
        try {
            const res = await axios.post(url, body, config);
            return {status: res.status,  data: res.data }
        } catch (err) {
            console.log(`Error: ${err}`);
            if (err.response && err.response.data) {
                NotificationManager.error(convertToTurkish(err.response.data));
            }
            return {status: "ERROR",  err: err }
        }
    } else if (method === "put") {
        try {
            const res = await axios.put(url, body, config);
            return {status: res.status,  data: res.data }
        } catch (err) {
            console.log(`Error: ${err}`);
            if (err.response && err.response.data) {
                NotificationManager.error(convertToTurkish(err.response.data));
            }
            return {status: "ERROR",  err: err }
        }
    }
}
