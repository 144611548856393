import { useEffect, useState } from "react";
import InputGroup from "../../inputs/InputGroup";
import PrimaryButton from "../../buttons/PrimaryButton";
import ApproveButton from "../../buttons/ApproveButton";
import {
  createHomework,
  updateHomework,
} from "../../../requests/ApiOperitions";
import { toast } from "react-toastify";
import language from "../../../context/language.json";

const CreateOrUpdateHomework = (props) => {
  const {
    initialHomework,
    treeList,
    branchId,
    schoolStaffId,
    handleAfterCreateOrUpdate,
  } = props;
  const [inputs, setInputs] = useState({
    id: null,
    treeId: null,
    branchId,
    homeworkStaff: schoolStaffId,
    beginAt: new Date().toISOString().split("T")[0],
    endAt: new Date().toISOString().split("T")[0],
  });

  // treeId, branchId, beginAt, endAt, homeworkStaff

  useEffect(() => {
    if (initialHomework) {
      setInputs({
        id: initialHomework.homeWorkId,
        treeId: initialHomework.treeId,
        branchId: initialHomework.branchId,
        homeworkStaff: initialHomework.homeworkStaff,
        beginAt: new Date(initialHomework.beginAt + 10800000)
          .toISOString()
          .split("T")[0],
        endAt: new Date(initialHomework.endAt + 10800000)
          .toISOString()
          .split("T")[0],
      });
    } else {
      if (treeList.length > 0) {
        setInputs({ ...inputs, treeId: treeList[0]["TreeID"] });
      }
    }
  }, [initialHomework]);



  const handleOnChange = (key, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [key]: value,
    }));
  };

  const handleUpdateOnClick = async () => {
    const { data, err } = await updateHomework(inputs.homeWorkId, {
      ...inputs,
      beginAt: new Date(inputs.beginAt).getTime(),
      endAt: new Date(inputs.endAt).getTime(),
    });
    if (err) {
      toast("Görev güncellenirken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  const handleCreateOnClick = async () => {
    const { data, err } = await createHomework({
      staff:{id:schoolStaffId},
      treeId:{id:inputs.treeId},
      branchId:{id:branchId},
      isBranchHomework:true,
      beginAt: new Date(inputs.beginAt).getTime(),
      endAt: new Date(inputs.endAt).getTime(),
    });
    if (err) {
      toast("Görev oluşturulurken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  return (
    <div className="w-full">
      <div className="my-2">
        <div className="font-bold">
          {inputs.id ? "Görev güncelle" : "Görev oluştur"}
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-400"></div>
      <div className="my-1">
        <div className="flex flex-row flex-wrap">
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                type="select"
                name="Ağaç"
                optionList={treeList}
                value={inputs.treeId}
                onChange={(e) => handleOnChange("treeId", e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                type="date"
                name="Başlama Tarihi"
                value={inputs.beginAt}
                onChange={(e) => handleOnChange("beginAt", e.target.value)}
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                type="date"
                name="Bitiş Tarihi"
                value={inputs.endAt}
                onChange={(e) => handleOnChange("endAt", e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-1">
        <div className="flex flex-row justify-end">
          {inputs.id ? (
            <PrimaryButton title={language.update} onClick={handleUpdateOnClick} />
          ) : (
            <ApproveButton title={language.insert} onClick={handleCreateOnClick} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateOrUpdateHomework;
