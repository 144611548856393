import { BrowserRouter, Route, Routes } from "react-router-dom";
import LeftPanelNavigation from "../panels/LeftPanelNavigation";
import { useApp } from "../context/AppContext";
import LoginScreen from "../screens/login/LoginScreen";
import { ClimbingBoxLoader } from "react-spinners";
import { useAuth } from "../context/UserContext";
import { DashboardScreen as StudentDashboardScreen } from "../screens/student/dashboard/DashboardScreen";
import { DashboardScreen as InstitutionDashboardScreen } from "../screens/institution/dashboard/DashboardScreen";
import { DashboardScreen as AdminDashboardScreen } from "../screens/admin/dashboard/DashboardScreen";
import { DashboardScreen as SuperAdminDashboardScreen } from "../screens/superadmin/dashboard/DashboardScreen";
import InstitutionReportScreen from "../screens/institution/page/InstitutionReportScreen";
import InstitutionBranchScreen from "../screens/institution/page/InstitutionBranchScreen";
import InstitutionStudentScreen from "../screens/institution/page/InstitutionStudentScreen";
import InstitutionTreeScreen from "../screens/institution/page/InstitutionTreeScreen";
import InstitutionSchoolReportScreen from "../screens/institution/page/InstitutionSchoolReportScreen";
import InstitutionLogScreen from "../screens/institution/page/InstitutionLogScreen";
import InstitutionTeacherScreen from "../screens/institution/page/InstitutionTeacherScreen";
import InstitutionHomeworkScreen from "../screens/institution/page/InstitutionHomeworkScreen";
import ErrorScreen from "../screens/error/ErrorScreen";
import InstitutionList from "../screens/institution/page/InstitutionList";
import { DemoReport } from "../screens/institution/page/DemoReport";
import InstitutionStaffScreen from "../screens/institution/page/InstitutionStaffScreen";
import DashboardTeacherScreen from "../screens/teacher/dashboard/DashboardTeacherScreen";
import IndividualStudentScreen from "../screens/institution/page/IndividualStudentScreen";
import CommentTask from "../screens/institution/page/CommentTask";
import {BranchReport} from "../screens/institution/page/BranchReport.js";
import {StudentReport} from "../screens/institution/page/StudentReport";
import ExportExcel from "../screens/institution/page/ExportExcel";
import CreateOrUpdateInstitution from "../components/institution/CreateOrUpdateInstitution";
import MainSchoolReport from "../page/MainSchoolReport";
import ExportUserPassword from "../screens/institution/page/ExportUserPassword";

const BaseRouterProvider = () => {
  const { appState } = useApp();
  const { userInformation } = useAuth();

  if (appState === "INITIALIZED" || appState === "INSTITUTION_QUERY_REQUIRED") {
    return (
      <div className="flex flex-row h-full px-4 py-12 bg-left-panel-selected">
        <div className="flex-1 flex flex-col items-center justify-center bg-left-panel rounded-3xl">
          <div className="w-[400px] my-10">
            <img className="w-full" src="/logos/Miamo4kids-Mindventure.png" />
          </div>
          <ClimbingBoxLoader color="#fd8500" size={25} />
        </div>
      </div>
    );
  } else if (appState === "ERROR") {
    return (
      <BrowserRouter>
        <div className="flex flex-row h-full px-4 py-12 bg-left-panel-selected">
          <Routes>
            <Route path="/*" element={<ErrorScreen />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  } else if (appState === "LOGIN_REQUIRED") {
    return (
      <BrowserRouter>
        <div className="flex flex-row h-full px-4 py-12 bg-left-panel-selected">
          <Routes>
            <Route path="/" element={<LoginScreen />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  } else if (appState === "DASHBOARD") {
    if (!userInformation) return;
    switch (userInformation.role) {
      case "SUPER_ADMIN":
        return (
          <BrowserRouter>
            <div className="h-full bg-left-panel-selected">
              <div className="w-full m-auto flex flex-row h-full px-4 py-12 ">
                <LeftPanelNavigation />
                <div className="p-5 bg-white w-full rounded-r-3xl overflow-y-auto">
                  <Routes>
                    <Route path="/" element={<SuperAdminDashboardScreen />} />
                    <Route path="/institutions" element={<InstitutionList />} />
                    <Route path="/institutions/:id" element={<CreateOrUpdateInstitution />} />

                    <Route path="/report/:type/:id" element={<MainSchoolReport />} />


                    <Route
                      path="/branches"
                      element={<InstitutionBranchScreen />}
                    />
                    <Route
                        path="/branches/id"
                        element={<BranchReport />}
                    />
                    <Route
                      path="/students"
                      element={<InstitutionStudentScreen />}
                    />
                    <Route
                        path="/students/:id"
                        element={<StudentReport />}
                    />
                    <Route path="/trees" element={<InstitutionTreeScreen />} />
                    <Route
                      path="/reports"
                      element={<InstitutionReportScreen />}
                    />
                    <Route
                      path="/school-reports"
                      element={<InstitutionSchoolReportScreen />}
                    />
                    <Route path="/logs" element={<InstitutionLogScreen />} />
                    <Route
                      path="/teachers"
                      element={<InstitutionTeacherScreen />}
                    />
                    <Route
                      path="/homeworks"
                      element={<InstitutionHomeworkScreen />}
                    />
                    <Route
                        path="/staffs"
                        element={<InstitutionStaffScreen />}
                    />
                    <Route
                        path="/individual"
                        element={<IndividualStudentScreen />}
                    />
                    <Route
                        path="/comments"
                        element={<CommentTask />}
                    />
                    <Route
                        path="/excel"
                        element={<ExportExcel />}
                    />
                    <Route
                        path="/password"
                        element={<ExportUserPassword />}
                    />
                    <Route path="/rapor" element={<DemoReport />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        );
      case "ADMIN":
        return (
          <BrowserRouter>
            <div className="h-full bg-left-panel-selected">
              <div className="w-full  m-auto flex flex-row h-full px-4 py-12 ">
                <LeftPanelNavigation />
                <div className="p-5 bg-white w-full rounded-r-3xl overflow-y-auto">
                  <Routes>
                    <Route path="/" element={<InstitutionDashboardScreen />} />
                    <Route
                      path="/branches"
                      element={<InstitutionBranchScreen />}
                    />
                    <Route
                      path="/students"
                      element={<InstitutionStudentScreen />}
                    />
                    <Route path="/trees" element={<InstitutionTreeScreen />} />
                    <Route
                      path="/reports"
                      element={<InstitutionReportScreen />}
                    />
                    <Route
                      path="/school-reports"
                      element={<InstitutionSchoolReportScreen />}
                    />
                    <Route path="/logs" element={<InstitutionLogScreen />} />
                    <Route
                      path="/teachers"
                      element={<InstitutionTeacherScreen />}
                    />
                    <Route
                      path="/homeworks"
                      element={<InstitutionHomeworkScreen />}
                    />
                    <Route
                        path="/excel"
                        element={<ExportExcel />}
                    />
                    <Route path="/rapor" element={<DemoReport />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        );
      case "MANAGER":
        return (
          <BrowserRouter>
            <div className="h-full bg-left-panel-selected">
              <div className="w-full  m-auto flex flex-row h-full px-4 py-12 ">
                <LeftPanelNavigation />
                <div className="p-5 bg-white w-full rounded-r-3xl overflow-y-auto">
                  <Routes>
                    <Route path="/" element={<AdminDashboardScreen />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        );
      case "USER":
        return (
          <BrowserRouter>
            <div className="h-full bg-left-panel-selected">
              <div className="w-full lg:w-[90%] xl:w-[80%] m-auto flex flex-row h-full px-4 py-12 ">
                <LeftPanelNavigation />
                <div className="p-5 bg-white w-full rounded-r-3xl overflow-y-auto">
                  <Routes>
                    <Route path="/" element={<StudentDashboardScreen />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
        );
      case "TEACHER":
        return (
            <BrowserRouter>
              <div className="h-full bg-left-panel-selected">
                <div className="w-full m-auto flex flex-row h-full px-4 py-12 ">
                  <LeftPanelNavigation />
                  <div className="p-5 bg-white w-full rounded-r-3xl overflow-y-auto">
                    <Routes>
                      <Route path="/" element={<DashboardTeacherScreen />} />
                      <Route
                          path="/branches"
                          element={<InstitutionBranchScreen />}
                      />
                      <Route
                          path="/students"
                          element={<InstitutionStudentScreen />}
                      />
                      <Route path="/trees" element={<InstitutionTreeScreen />} />
                      <Route
                          path="/reports"
                          element={<InstitutionReportScreen />}
                      />
                      <Route
                          path="/school-reports"
                          element={<InstitutionSchoolReportScreen />}
                      />
                      <Route path="/logs" element={<InstitutionLogScreen />} />
                      <Route
                          path="/teachers"
                          element={<InstitutionTeacherScreen />}
                      />
                      <Route
                          path="/homeworks"
                          element={<InstitutionHomeworkScreen />}
                      />
                      <Route path="/rapor" element={<DemoReport />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </BrowserRouter>
        );
    }
  }
};

export default BaseRouterProvider;
