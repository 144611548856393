import React from "react";
import { useEffect, useState } from "react";
import MainHeader from "../../../components/headers/MainHeader";
import BaseTable from "../../../components/tables/BaseTable";
import { retrieveTreeList } from "../../../requests/ApiOperitions";
import { toast } from "react-toastify";

export default function InstitutionTreeScreen() {

  const [treeList, setTreeList] = useState([]);
  useEffect(() => {
    const retrieveAndSetTreeList = async () => {
      const { data, err } = await retrieveTreeList();
      if (err) {
        toast("Ağaç listesi gelirken bir hata oluştu");
        return;
      }
      setTreeList(data);
    };
    retrieveAndSetTreeList();
  }, []);

  const RenderTableData = () => {
    return (
      <div className="relative overflow-x-auto">
        <BaseTable
          headerList={[{ text: "ID" }, { text: "isim" }]}
          dataList={treeList}
          dataKeyList={[{ key: "id" }, { key: "name" }]}
          updatable={false}
        />
      </div>
    );
  };

  return (
    <div className="w-full">
      <MainHeader name="Ağaçlar" />
      <div>
        <RenderTableData />
      </div>
    </div>
  );
}
