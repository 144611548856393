import { useState } from "react";
import HorizontalDividerLine from "../../components/dividers/HorizontalDividerLine";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useApp } from "../../context/AppContext";
import { toast } from "react-toastify";
import { useAuth } from "../../context/UserContext";
import InputGroup from "../../components/inputs/InputGroup";
import { authenticateByUsernameAndPassword } from "../../requests/ApiOperitions";

const LoginScreen = () => {
  const {
    setAppState,
    setTheme,
    setPageList,
    _setSelectedStudent,
    _setSelectedTeacher,
    _setSelectedBranch,
    _setSelectedSchool,
    setRole,
    appState,
    theme,
    pageList,
    _selectedSchool,
    _selectedBranch,
    _electedTeacher,
    _selectedStudent,
    role
  } = useApp();
  const { setUserInformation, setInstitutionId } =
    useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleOnChange = (key, value) => {
    if (key === "username") {
      setUsername(value);
    } else if (key === "password") {
      setPassword(value);
    }
  };

  const handleLoginOnClick = async () => {
    const { data, err } = await authenticateByUsernameAndPassword({
      username,
      password,
    });
    if (err) {
      toast("Hatalı kullanıcı adı veya şifre");
      return;
    }

    const { accessToken, refreshToken, user } = data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    setUserInformation(user);


    setAppState("INSTITUTION_QUERY_REQUIRED");


    if (user.role !== "SUPER_ADMIN") {
      setInstitutionId(user.school.id);
     // setAppState("DASHBOARD");
    }


  };

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <div className="w-[400px] h-[600px] bg-left-panel rounded-3xl">
        <div className="flex flex-col items-center p-4">
          <div className="w-[200px] mt-10 mb-5">
            <img className="w-full" src="/logos/Miamo4kids-Mindventure.png" alt="miamo" />
          </div>
          <div className="w-full mb-5">
            <HorizontalDividerLine color={"#ffffff"} />
          </div>
          <InputGroup
            type={"text"}
            value={username}
            placeholder={"Kullanıcı adı"}
            onChange={(e) => {
              handleOnChange("username", e.target.value);
            }}
          />
          <InputGroup
            type={"password"}
            value={password}
            placeholder={"Şifre"}
            onChange={(e) => {
              handleOnChange("password", e.target.value);
            }}
          />
          <div className="mt-10">
            <PrimaryButton title={"Giriş yap"} onClick={handleLoginOnClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
