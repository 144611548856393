import React, {useEffect, useState} from "react";
import {useAuth} from "../../../../context/UserContext";

export default function UserCountPart (props){
    const {userStatistics} = props
    const [_userStatistics, setUserStatistics] = useState([]);
    const {institutionId} = useAuth()



    useEffect(() => {
        setUserStatistics(props.userStatistics)
    }, []);
    useEffect(() => {
        setUserStatistics(props.userStatistics)
    }, [props]);

    useEffect(() => {
        setUserStatistics(props.userStatistics)
    }, [institutionId]);



    return (

        <>
            {
                institutionId > -1 ?
                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-xs uppercase bg-left-panel text-text-color">
                            <tr>
                                <th scope="col" className="px-6 py-3"> Rol</th>
                                <th scope="col" className="px-6 py-3"> Sınıf</th>
                                <th scope="col" className="px-6 py-3"> Adet</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                _userStatistics ?
                                    _userStatistics.map((data, key) => (
                                        <tr key={key} className="bg-white border-b">
                                            <td className={`px-6 py-4`}>{data.role}</td>
                                            <td className={`px-6 py-4`}>{data.ageGroup.name}</td>
                                            <td className={`px-6 py-4`}>{data.count}</td>
                                        </tr>
                                    )) : null}
                            </tbody>
                        </table>
                    </div>
                    : null
            }


        </>
    )
}