import React from "react";

export default function CommentPart (props){
        const {commandText} = props;
        const parseCommandText = commandText => {
                return(
                    commandText.split('/n').map((line, key) => {
                            return <div key={key}>{line.trim()}</div>
                    })
                )
        }
    return (
        <div>
                {parseCommandText(commandText)}
        </div>
    )
}